import React from 'react';
import { InputField, RadioButton } from 'components/Global';
import CheckboxField from 'components/Global/CheckboxField';

// ARRAY RADIO BUTTON
const arrayRadionButton = [
  {
    id: 'cfpf1',
    label: 'Harga Fixed',
    value: 'fixed',
  },
  {
    id: 'cfpf2',
    label: 'Harga Percentage',
    value: 'percentage',
  },
];

const arrayRadion2Button = [
  {
    id: 'gnrf1',
    label: 'Harga Fixed',
    value: 'fixed',
  },
  {
    id: 'gnrf2',
    label: 'Harga Percentage',
    value: 'percentage',
  },
];

const UserStatus = ({ isGetAndRideFeeAvailable, setIsGetAndRideFeeAvailable, disable }) => {
  return (
    <div className="status-info">
      <div className="status-info__toggle-wrapper">
        <div className="user__toggle-status">
          <label className="switch">
            <input
              type="checkbox"
              onChange={(e) => setIsGetAndRideFeeAvailable(e.target.checked)}
              role={'switch'}
              checked={isGetAndRideFeeAvailable}
              disabled={disable}
            />
            <span className="slider"></span>
          </label>
        </div>
      </div>
    </div>
  );
};

const ServicesForm = ({ services, setServices, item, isSpecialAirportTransfer, setIsSpecialAirportTransfer }) => {
  const checkActiveHandler = (e) => {
    const checked = e.target.checked;

    if (checked) {
      setServices((prevState) => [
        ...prevState,
        {
          sub_service_id: item.id,
          driver_fee_type: '',
          driver_fee_amount: 0,
          is_active_fee_getandride: false,
          fee_getandride_type: '',
          fee_getandride_amount: 0,
        },
      ]);
    } else {
      const filteredServices = services?.filter((ser) => ser.sub_service_id !== item.id);
      setServices(filteredServices);
    }
  };

  const priceFeeTypeHandler = (id) => {
    const newServices = services?.map((ser) => {
      if (ser.sub_service_id === item.id) {
        return {
          ...ser,
          driver_fee_type: arrayRadionButton?.find((type) => type.id === id)?.value,
          driver_fee_amount: 0,
        };
      }

      return ser;
    });
    setServices(newServices);
  };

  const changeDriverFeeHandler = (e) => {
    const newServices = services?.map((ser) => {
      if (ser.sub_service_id === item.id) {
        return {
          ...ser,
          driver_fee_amount: +e.target.value,
        };
      }

      return ser;
    });
    setServices(newServices);
  };

  const checkGetAndRideFeehandler = (checked) => {
    const newServices = services?.map((ser) => {
      if (ser.sub_service_id === item.id) {
        return {
          ...ser,
          is_active_fee_getandride: checked,
          fee_getandride_type: '',
          fee_getandride_amount: 0,
        };
      }

      return ser;
    });
    setServices(newServices);
  };

  const getAndRideFeeTypeHandler = (id) => {
    const newServices = services?.map((ser) => {
      if (ser.sub_service_id === item.id) {
        return {
          ...ser,
          fee_getandride_type: arrayRadion2Button?.find((type) => type.id === id)?.value,
          fee_getandride_amount: 0,
        };
      }

      return ser;
    });
    setServices(newServices);
  };

  const changeGetAndRideFeeHandler = (e) => {
    const newServices = services?.map((ser) => {
      if (ser.sub_service_id === item.id) {
        return {
          ...ser,
          fee_getandride_amount: +e.target.value,
        };
      }

      return ser;
    });
    setServices(newServices);
  };

  return (
    <div className="services-form">
      <div className="services-form__active-checkbox">
        <CheckboxField
          label="Active"
          name="active"
          value="active"
          checked={services?.find((ser) => ser.sub_service_id === item.id)}
          onChange={checkActiveHandler}
        />
        {item?.name === 'Airport Transfer' && (
          <CheckboxField
            label="Driver Car"
            name="driver-car"
            value="driver-car"
            checked={isSpecialAirportTransfer}
            onChange={(e) => {
              setIsSpecialAirportTransfer(e.target.checked);
            }}
          />
        )}
      </div>

      <div className="services-form__form">
        <div className="services-form__form__column">
          <InputField
            type="number"
            placeholder={
              services?.find((ser) => ser.sub_service_id === item.id)?.driver_fee_type === 'fixed' ? '0' : '0%'
            }
            label="Fee Driver"
            htmlFor="fee-driver"
            value={services?.find((ser) => ser.sub_service_id === item.id)?.driver_fee_amount || ''}
            onChange={changeDriverFeeHandler}
            disable={
              !services?.find((ser) => ser.sub_service_id === item.id) ||
              !services?.find((ser) => ser.sub_service_id === item.id)?.driver_fee_type
            }
          />
          <div className="config-price">
            <RadioButton
              name="price-fee"
              data={arrayRadionButton}
              state={
                arrayRadionButton.find(
                  (type) => type.value === services?.find((ser) => ser.sub_service_id === item.id)?.driver_fee_type,
                )?.id
              }
              setState={priceFeeTypeHandler}
              disable={!services?.find((ser) => ser.sub_service_id === item.id)}
            />
          </div>
        </div>
        <div className="services-form__form__column">
          <InputField
            type="number"
            placeholder={
              services?.find((ser) => ser.sub_service_id === item.id)?.fee_getandride_type === 'fixed' ? '0' : '0%'
            }
            label="Fee Get&Ride"
            htmlFor="fee-get&ride"
            value={services?.find((ser) => ser.sub_service_id === item.id)?.fee_getandride_amount || ''}
            onChange={changeGetAndRideFeeHandler}
            disable={
              !services?.find((ser) => ser.sub_service_id === item.id)?.is_active_fee_getandride ||
              !services?.find((ser) => ser.sub_service_id === item.id)?.fee_getandride_type
            }
          />
          <div className="config-price">
            <UserStatus
              isGetAndRideFeeAvailable={
                services?.find((ser) => ser.sub_service_id === item.id)?.is_active_fee_getandride
              }
              setIsGetAndRideFeeAvailable={(cheked) => checkGetAndRideFeehandler(cheked)}
              disable={!services?.find((ser) => ser.sub_service_id === item.id)}
            />
            <RadioButton
              name="get-and-ride-fee"
              data={arrayRadion2Button}
              state={
                arrayRadion2Button.find(
                  (type) => type.value === services?.find((ser) => ser.sub_service_id === item.id)?.fee_getandride_type,
                )?.id
              }
              setState={getAndRideFeeTypeHandler}
              disable={
                !services?.find((ser) => ser.sub_service_id === item.id) ||
                !services?.find((ser) => ser.sub_service_id === item.id)?.is_active_fee_getandride
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesForm;

import { useState } from 'react';
import clsx from 'clsx';
import { BookingZone, InputField, TableWrapper } from 'components/Global';
import InputTelpField from 'components/Global/InputTelpField';
import VehiclePhotos from '../VechiclePhotos';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { ReactComponent as Mappin } from 'icons/mappin-icon-blue.svg';
import { ReactComponent as Chevron } from 'icons/arrow-down-icon.svg';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { setCurrency } from 'utils/functionality';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const WithDriverTask = ({ data, customer, setShowImage, selectedDriverTaskData }) => {
  const [accordionNumberToShow, setAccordionNumberToShow] = useState(0);

  const mappingPhotoData = (data, date, status) => {
    if (!data) {
      return {};
    }

    const theItem = data.find(
      (item) => format(new Date(item.working_date_time), 'yyyy-MM-dd') === date && item.status === status,
    );

    return theItem;
  };

  const getVehiclePlate = (orderType) => {
    if (!orderType) return;

    if (orderType == '1' || orderType == '2') {
      return data?.order_detail?.vehicle?.license_number;
    } else if (orderType == '3' || orderType == '4') {
      return data?.partner_vehicle?.license_plate;
    }
  };

  const getVehicleName = (orderType) => {
    if (!orderType) return;

    if (orderType == '1' || orderType == '2') {
      return `${data?.order_detail?.vehicle?.brand_name} ${data?.order_detail?.vehicle?.name}`;
    } else if (orderType == '3' || orderType == '4') {
      return `${data?.partner_vehicle?.name}`;
    }
  };

  return (
    <>
      <TableWrapper icon={<CarIcon fill="#009EF7" width="25px" height="25px" />} title="Driver Task">
        <h2>Detail Penyewa</h2>
        <div className="detail-driver-task__order-detail">
          <InputField label="Nama Lengkap" disable readOnly value={data?.user_name || '-'} />

          <InputField label="No Order" disable readOnly value={data?.order_key || '-'} />

          <InputTelpField
            label="No Handphone"
            countryCodeValue={data?.phone_country_code || '+62'}
            disable
            readOnly
            value={data?.phone_number || '-'}
          />

          <InputField label="Plat Nomer" disable readOnly value={getVehiclePlate(data?.order_type_id) || '-'} />

          <InputTelpField
            label="Whatsapp"
            countryCodeValue={data?.phone_country_code || '+62'}
            disable
            readOnly
            value={data?.wa_number || '-'}
          />

          <InputField label="Negara" disable readOnly value={data?.phone_country_code === '+62' ? 'Indonesia' : '-'} />

          <div className="identity-image">
            <h3>Foto SIM</h3>
            <div className="image">
              <div
                className="image-wrapper"
                onClick={() => {
                  if (data?.is_admin_creation && data?.order_detail?.identity?.sim) {
                    return setShowImage({
                      show: true,
                      image: data?.order_detail.identity?.sim,
                    });
                  } else if (!data?.order_detail?.identity?.sim && customer?.PersonalInfos?.sim) {
                    return setShowImage({
                      show: true,
                      image: `${IMAGE_BASE_URL}${customer?.PersonalInfos?.sim}`,
                    });
                  }
                }}
                style={{
                  cursor: data?.order_detail?.identity?.sim || customer?.PersonalInfos?.sim ? 'pointer' : 'default',
                }}
              >
                {data?.is_admin_creation && data?.order_detail?.identity?.sim ? (
                  <img src={data?.order_detail?.identity?.sim} alt="sim image" />
                ) : !data?.is_admin_creation && customer?.PersonalInfos?.sim ? (
                  <img src={`${IMAGE_BASE_URL}${customer?.PersonalInfos?.sim}`} alt="sim image" />
                ) : (
                  <div className="no-available">
                    <img src="/assets/image/no-image-available.png" className="img" />
                    <p>No Image Available</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="identity-image">
            <h3>Foto KTP</h3>
            <div className="image">
              <div
                className="image-wrapper"
                onClick={() => {
                  if (data?.is_admin_creation && data?.order_detail?.identity?.ktp) {
                    return setShowImage({
                      show: true,
                      image: data?.order_detail.identity?.ktp,
                    });
                  } else if (!data?.order_detail?.identity?.ktp && customer?.PersonalInfos?.ktp) {
                    return setShowImage({
                      show: true,
                      image: `${IMAGE_BASE_URL}${customer?.PersonalInfos?.ktp}`,
                    });
                  }
                }}
                style={{
                  cursor: data?.order_detail?.identity?.ktp || customer?.PersonalInfos?.ktp ? 'pointer' : 'not-allowed',
                }}
              >
                {data?.is_admin_creation && data?.order_detail?.identity?.ktp ? (
                  <img src={data?.order_detail?.identity?.ktp} alt="sim image" />
                ) : !data?.is_admin_creation && customer?.PersonalInfos?.ktp ? (
                  <img src={`${IMAGE_BASE_URL}${customer?.PersonalInfos?.ktp}`} alt="sim image" />
                ) : (
                  <div className="no-available">
                    <img src="/assets/image/no-image-available.png" className="img" />
                    <p>No Image Available</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <h2>Detail Sewa</h2>
        <div className="detail-driver-task__order-detail">
          <InputField
            label="Tipe Sewa"
            disable
            readOnly
            value={data?.order_type_id === 1 ? 'Harian' : 'Airport Transfer'}
          />

          <InputField label="Jenis Mobil" disable readOnly value={getVehicleName(data?.order_type_id)} />

          <div className="rent-period">
            <InputField
              label="Tanggal Mulai"
              disable
              readOnly
              value={
                data?.order_detail?.start_booking_date
                  ? format(new Date(data?.order_detail?.start_booking_date), 'dd MMMM yyyy', { locale: id })
                  : '-'
              }
            />
            <InputField
              label="Jam Mulai"
              disable
              readOnly
              value={
                data?.order_detail?.start_booking_date && data?.order_detail?.start_booking_time
                  ? format(
                      new Date(`${data?.order_detail?.start_booking_date} ${data?.order_detail?.start_booking_time}`),
                      'hh:mm aa',
                      { locale: id },
                    )
                  : '-'
              }
            />
          </div>

          <div className="rent-period">
            <InputField
              label="Tanggal Selesai"
              disable
              readOnly
              value={
                data?.order_detail?.end_booking_date
                  ? format(new Date(data?.order_detail?.end_booking_date), 'dd MMMM yyyy', { locale: id })
                  : '-'
              }
            />
            <InputField
              label="Durasi"
              disable
              readOnly
              value={data?.order_detail?.booking_zones?.length + ' Hari' || '-'}
            />
          </div>

          <div className="rent-payment-type">
            <InputField
              label="Tipe Pembayaran"
              disable
              readOnly
              value={data?.type === 'FULL' ? 'Pembayaran Full' : 'Pembayaran DP 50%'}
            />

            <InputField label="Jumlah Penumpang" disable readOnly value={data?.order_detail?.passenger_number || '0'} />
          </div>

          <div className="rent-baggage">
            <InputField
              label="Kapasitas Koper"
              disable
              readOnly
              value={data?.order_detail?.vehicle?.max_suitcase || '0'}
            />
            <InputField label="Koper yang dibawa" disable readOnly value={data?.order_detail?.baggage || '0'} />
          </div>
        </div>

        {data?.order_type_id != '3' && (
          <div className="booking-zone">
            <h2>Zona Penyewaan</h2>
            <BookingZone
              startDate={new Date(data?.order_detail?.start_booking_date)}
              durationRentDate={data?.order_detail?.booking_zones?.length}
              selectedCar={data?.order_detail?.vehicle}
              orderData={{ order_detail: { booking_zones: data?.order_detail?.booking_zones } }}
              isOrderConfirmation={true}
              forCustomOrder={false}
              locTimeId={data?.order_detail?.loc_time_id}
              rentalLocationId={data?.order_detail?.location_id}
            />
          </div>
        )}

        {selectedDriverTaskData?.status !== 'OPEN' && (
          <>
            <h2>Detail Driver</h2>
            <div className="detail-driver-task__driver-detail">
              <InputField
                label="Fee Per-Task"
                disable
                readOnly
                value={
                  selectedDriverTaskData?.fee_per_task
                    ? setCurrency(selectedDriverTaskData.fee_per_task).replace('Rp', data?.currency)
                    : '-'
                }
              />

              <InputField label="ID Driver" disable readOnly value={selectedDriverTaskData?.driver_id || '-'} />
            </div>
          </>
        )}
      </TableWrapper>

      <ul className="detail-driver-task__vehicle-photos-task">
        {data?.order_detail?.booking_zones.map((item, index) => (
          <li key={item.date} className="list">
            <header
              className="list-header"
              onClick={() => setAccordionNumberToShow((prevState) => (prevState === index + 1 ? 0 : index + 1))}
            >
              <div>
                <Mappin />
              </div>
              <p>Hari {index + 1}</p>
              <Chevron className="arrow" />
            </header>

            <section className={clsx('list-section', accordionNumberToShow === index + 1 ? 'show' : '')}>
              <VehiclePhotos
                title="Ambil dari Garasi"
                photoData={mappingPhotoData(selectedDriverTaskData?.with_driver_task_progression, item.date, 'RUNNING')}
                setShowImage={setShowImage}
              />
              {/* <VehiclePhotos title="Antar Mobil" photoData={photoData} setShowImage={setShowImage} />
              <VehiclePhotos title="Ambil Mobil" photoData={photoDataTakeVehicle} setShowImage={setShowImage} /> */}
              <VehiclePhotos
                title="Parkir ke Garasi"
                photoData={mappingPhotoData(selectedDriverTaskData?.with_driver_task_progression, item.date, 'FINISH')}
                setShowImage={setShowImage}
              />
            </section>
          </li>
        ))}
      </ul>
    </>
  );
};

export default WithDriverTask;

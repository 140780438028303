import PreviewImage from 'components/Modals/PreviewImage';
import { useState } from 'react';
import ReactDOM from 'react-dom';

function isImage(fileType) {
  return fileType.startsWith('image/');
}

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

export const ChatMessage = ({ message, file, file_type, user_id, adminId }) => {
  const [showImage, setShowImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const imagePreviewHandler = (image) => {
    setShowImage(true);
    setSelectedImage(image);
  };

  return (
    <>
      <div
        className={`chat__message-item ${
          user_id === adminId ? 'chat__message-item__other' : 'chat__message-item__user'
        }`}
      >
        <div
          className={`chat__message-content ${
            user_id === adminId ? 'chat__message-content__other' : 'chat__message-content__user'
          }`}
        >
          {/* {message ? message : null} */}
          {message}
          {file && isImage(file_type) ? (
            <div className="chat__message-content__image" onClick={() => imagePreviewHandler(IMAGE_BASE_URL + file)}>
              <img src={IMAGE_BASE_URL + file} alt="" />
            </div>
          ) : null}
        </div>
      </div>

      {showImage &&
        ReactDOM.createPortal(
          <PreviewImage image={selectedImage} setShow={() => setShowImage(false)} />,
          document.getElementById('modal'),
        )}
    </>
  );
};

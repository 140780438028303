import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import useDebounce from 'utils/useDebounce';
import { getAllCategories } from 'features/category/actions';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { resetAirportPackageData } from 'features/airport-packages/slice';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import EmptyState from 'components/Global/EmptyState';
import SearchBar from 'components/Global/TableWrapper/SearchBar';
import { ReactComponent as AirportTransferCarFilledIcon } from 'icons/airport-transfer-car-filled-icon.svg';
import { checkPermission } from 'utils/functionality';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Kategori Mobil', value: 'category_name' },
  { header: 'Lokasi', value: 'location_name' },
];

const Harga = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setShowSpinner } = useAppContext();
  const { data: categoryData, status } = useSelector((state) => state.category);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [sortBy, setSortBy] = useState({ count: 0, sortValue: '' });
  const [orderSeq, setOrderSeq] = useState('desc');

  const searchValueDebounce = useDebounce(searchValue, 1000);

  const detailHandler = (e) => {
    const categoryId = e.target.parentElement.parentElement.dataset['key'];

    const locationName = e.target.parentElement.parentElement.previousSibling.innerText;
    const locationId = rentalLocationData?.find((item) => item.name === locationName)?.id;

    navigate(`/airport-transfer-car/price?mode=detail&category_id=${categoryId}&location_id=${locationId}`);
  };

  const editHandler = (e) => {
    const categoryId = e.target.parentElement.parentElement.dataset['key'];

    const locationName = e.target.parentElement.parentElement.previousSibling.innerText;
    const locationId = rentalLocationData?.find((item) => item.name === locationName)?.id;
    dispatch(resetAirportPackageData());

    navigate(`/airport-transfer-car/price?mode=edit&category_id=${categoryId}&location_id=${locationId}`);
  };

  const sortByHandler = (sortValue) => {
    if (sortValue === sortBy.sortValue) {
      if (sortBy.count === 1) {
        setSortBy({ count: sortBy.count + 1, sortValue });
        setOrderSeq('desc');
        if (currentPage === 1) {
          dispatch(
            getAllCategories({
              page: 1,
              sortBy: sortValue,
              sortOrder: 'desc',
              search: searchValue,
              useGroupAirportPackage: true,
              limit: 10,
            }),
          );
        } else {
          setCurrentPage(1);
        }
      } else if (sortBy.count === 2) {
        setSortBy({ count: 0, sortValue: '' });
        setOrderSeq('desc');
        if (currentPage === 1) {
          dispatch(
            getAllCategories({
              page: 1,
              sortBy: '',
              sortOrder: 'desc',
              search: searchValue,
              useGroupAirportPackage: true,
              limit: 10,
            }),
          );
        } else {
          setCurrentPage(1);
        }
      }
    } else {
      setSortBy({ count: 1, sortValue });
      setOrderSeq('asc');
      if (currentPage === 1) {
        dispatch(
          getAllCategories({
            page: 1,
            sortBy: sortValue,
            sortOrder: 'asc',
            search: searchValue,
            useGroupAirportPackage: true,
            limit: 10,
          }),
        );
      } else {
        setCurrentPage(1);
      }
    }
  };

  useEffect(() => {
    dispatch(getAllRentalLocation());
  }, []);

  useEffect(() => {
    dispatch(
      getAllCategories({
        page: currentPage,
        sortBy: sortBy.sortValue,
        sortOrder: 'asc',
        search: searchValue,
        useGroupAirportPackage: true,
        limit: 10,
      }),
    );
  }, [currentPage]);

  useEffect(() => {
    dispatch(
      getAllCategories({
        page: 1,
        search: searchValueDebounce ? searchValueDebounce : undefined,
        sortBy: sortBy.sortValue,
        sortOrder: orderSeq,
        useGroupAirportPackage: true,
        limit: 10,
      }),
    );
  }, [searchValueDebounce]);

  useEffect(() => {
    if (!categoryData || !Object.keys(categoryData).length || !categoryData.category) return;

    const mappingCategoryData = categoryData.category.map((cat) => ({
      id: cat.id,
      category_name: cat.name,
      location_name: cat.location?.name || '-',
    }));
    setTableData(mappingCategoryData);
  }, [categoryData]);

  useEffect(() => {
    if (status === 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [status]);

  return (
    <TableWrapper
      icon={<AirportTransferCarFilledIcon fill="#009EF7" width="25px" height="25px" />}
      title="Airport Transfer Car"
      style={{ minHeight: '603px', position: 'relative' }}
    >
      <div className="filter-wrapper">
        <SearchBar
          type="text"
          value={searchValue}
          placeholder="Search by Name"
          onChange={setSearchValue}
          style={{ marginLeft: 8 }}
        />
      </div>
      {(!categoryData || !Object.keys(categoryData).length || !categoryData.category) && status !== 'loading' ? (
        <EmptyState />
      ) : (
        <>
          <Table
            column={column}
            data={tableData}
            actionBtn
            onDetail={detailHandler}
            onEdit={checkPermission(offCanvasMenu, currentMenu, 'update') ? editHandler : false}
            currentPage={currentPage}
            sort
            columnToSort={['Nama Kategori Mobil', 'Lokasi']}
            sortBy={sortBy}
            sortByHandler={sortByHandler}
          />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={categoryData?.pagination?.total_data}
          />
        </>
      )}
    </TableWrapper>
  );
};

export default Harga;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AirportIcon } from 'icons/airport-transfer-filled.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { checkEmptyObject } from 'utils/functionality';
import countryCodes from 'utils/country-codes.json';
import { getCustomerData } from 'features/user/slice';
import {
  changeOrderStatus,
  fetchOrderByIdWithApproval,
  getAirportPackageById,
  getVehicleAirportTransferPackage,
} from 'features/orders/actions';
import { getAllCouriers, getDetailTaskCourier } from 'features/couriers/actions';
import { getVehicleById } from 'features/vehicle/action';
import BankTransferConfirmation from './BankTransferConfirmation';
import { getAllRentalLocation } from 'features/rental-location/actions';
import ReactDOM from 'react-dom';
import PriceDifferenceModal from 'components/Modals/PriceDifferenceModal';
import { updateApprovalStatus } from 'features/approvals/actions';
import RegularOrderDetail from './regular-detail-order';
import SpecialOrderDetail from './special-detail-order';

const DetailOrder = ({ orderData: data, imagePreviewHandler, transactionKey }) => {
  const { showToast, setShowConfirmation } = useAppContext();

  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    code: '',
  });
  const [showBankTransferConfirmation, setShowBankTransferConfirmation] = useState(false);
  const [isPaymentValid, setIsPaymentValid] = useState(false);
  const [assignedCourier, setAssignedCourier] = useState(0);
  const [selectedCar, setSelectedCar] = useState(undefined);
  const [allVehicle, setAllVehicle] = useState([]);
  const [oldOrderData, setOldOrderData] = useState({});
  const [newOrderData, setNewOrderData] = useState({});
  const [showPriceDifference, setShowPriceDifference] = useState(false);

  const customer = useSelector(getCustomerData);
  const vehicles = useSelector((state) => state.airportTransferOrder.airportTransferCar);
  const courierTask = useSelector((state) => state.courierTask?.selectedCourierTasks);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const findCountryByCode = (code) => countryCodes.find((item) => item.dial_code == code);

  useEffect(() => {
    dispatch(getAllCouriers());
    dispatch(getAllRentalLocation());
  }, []);

  useEffect(() => {
    dispatch(fetchOrderByIdWithApproval(transactionKey));
  }, [transactionKey]);

  useEffect(() => {
    if (checkEmptyObject(data)) return;
    setOldOrderData(data || {});
    setNewOrderData(data?.approval?.data || {});
  }, [data]);

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(data, 'order_detail')) return;
    if (!transactionKey) return;
    if (data.order_type_id === 7) return;
    if (data.order_status === 'COMPLETED' || data.order_status === 'FINISHED') {
      dispatch(getDetailTaskCourier(data.transaction_key));
    }

    dispatch(
      getAirportPackageById({
        id: data.airport_transfer_package_id,
        pickupTrip: `${data.order_detail?.start_booking_date} ${data.order_detail?.start_booking_time.slice(0, 5)}`,
      }),
    );
    dispatch(
      getVehicleAirportTransferPackage({
        id: data.airport_transfer_package_id,
        locationRental: data.order_detail.location_id,
        pickupTrip: `${data.order_detail?.start_booking_date} ${data.order_detail?.start_booking_time.slice(0, 5)}`,
        page: 1,
      }),
    );
    dispatch(getVehicleById(data.order_detail.vehicle_id));
  }, [data]);

  useEffect(() => {
    if (Object.keys(vehicles).length === 0 || !vehicles.vehicles) return;
    setAllVehicle(
      [...vehicles.vehicles].map((item) => ({
        ...item,
        vehicle_image: item.images ? item.images[item.images.length - 1] : '',
      })),
    );
  }, [vehicles]);

  useEffect(() => {
    if (customer) {
      setSelectedCountry(findCountryByCode(data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code));
    }
  }, [customer, data]);

  useEffect(() => {
    if (Object.keys(courierTask).length > 0) {
      setAssignedCourier(+courierTask?.order?.courier_task?.courier_id);
    }
    setSelectedCar(() => allVehicle?.find((item) => item?.vehicle_id === data.order_detail?.vehicle_id));
  }, [courierTask, allVehicle]);

  const handleProcess = () => {
    let prevStatus;
    let nextStatus;
    let confirmationMessage;
    let toastSuccessMessage;
    let toastErrorMessage;

    switch (data?.order_status) {
      case 'CHECKOUT':
        prevStatus = 'CHECKOUT';
        nextStatus = 'PAID';
        confirmationMessage = 'Apakah anda yakin ingin mengkonfirmasi transaksi?';
        toastSuccessMessage = 'Transaksi Berhasil Dikonfirmasi';
        toastErrorMessage = 'Transaksi Gagal Dikonfirmasi!';
        break;
      case 'PAID':
        prevStatus = 'PAID';
        nextStatus = 'COMPLETED';
        confirmationMessage = 'Apakah anda yakin ingin memproses transaksi?';
        toastSuccessMessage = 'Transaksi Berhasil Diproses';
        toastErrorMessage = 'Transaksi Gagal Diproses!';
        break;
      case 'REVIEWING_IDENTITY':
        prevStatus = 'REVIEWING_IDENTITY';
        nextStatus = 'COMPLETED';
        confirmationMessage = 'Apakah anda yakin ingin memproses transaksi?';
        toastSuccessMessage = 'Transaksi Berhasil Diproses';
        toastErrorMessage = 'Transaksi Gagal Diproses!';
        break;
      default:
        throw new Error('order status not found!');
    }

    if (data?.order_status === 'PAID') {
      // if (!assignedCourier) {
      //   showToast({ type: 'error', message: 'Pilih Driver' });
      //   return;
      // }
      if (!selectedCar) {
        showToast({ type: 'error', message: 'Pilih Mobil' });
        return;
      }
    }

    const payload = {
      prevStatus,
      nextStatus,
      transaction_key: data?.transaction_key,
      airport_package_vehicle_id: selectedCar?.vehicle_id,
      courier_id: +assignedCourier,
    };

    setShowConfirmation({
      message: confirmationMessage,
      show: true,
      onClick: async () => {
        try {
          if (data?.order_approval_status?.includes('UPDATE_ORDER')) {
            await dispatch(updateApprovalStatus({ status: 'approved', id: data?.approval?.id, skip: true })).unwrap();
          } else {
            await dispatch(changeOrderStatus(payload)).unwrap();
          }
          showToast({ type: 'success', message: toastSuccessMessage });
        } catch (err) {
          showToast({ type: 'error', message: toastErrorMessage });
        } finally {
          navigate(-1);
        }
      },
    });
  };

  const showRegularOrderDetail = !showBankTransferConfirmation && data.order_type_id === 2;
  const showSpecialOrderDetail = !showBankTransferConfirmation && data.order_type_id === 7;

  return (
    <>
      {showBankTransferConfirmation ? (
        <BankTransferConfirmation
          imagePreviewHandler={imagePreviewHandler}
          orderData={data}
          setShowBankTransferConfirmation={setShowBankTransferConfirmation}
          setIsPaymentValid={setIsPaymentValid}
          isPaymentValid={isPaymentValid}
          handleProcess={handleProcess}
        />
      ) : null}

      {showRegularOrderDetail ? (
        <RegularOrderDetail
          data={data}
          setShowPriceDifference={setShowPriceDifference}
          selectedCar={selectedCar}
          setSelectedCar={setSelectedCar}
          allVehicle={allVehicle}
          newOrderData={newOrderData}
          oldOrderData={oldOrderData}
          customer={customer}
          imagePreviewHandler={imagePreviewHandler}
          selectedCountry={selectedCountry}
          setShowBankTransferConfirmation={setShowBankTransferConfirmation}
          isPaymentValid={isPaymentValid}
          handleProcess={handleProcess}
        />
      ) : null}

      {showSpecialOrderDetail ? (
        <SpecialOrderDetail
          data={data}
          setShowPriceDifference={setShowPriceDifference}
          selectedCar={selectedCar}
          setSelectedCar={setSelectedCar}
          allVehicle={allVehicle}
          newOrderData={newOrderData}
          oldOrderData={oldOrderData}
          customer={customer}
          imagePreviewHandler={imagePreviewHandler}
          selectedCountry={selectedCountry}
          setShowBankTransferConfirmation={setShowBankTransferConfirmation}
          isPaymentValid={isPaymentValid}
          handleProcess={handleProcess}
        />
      ) : null}

      {showPriceDifference &&
        ReactDOM.createPortal(
          <PriceDifferenceModal
            setShowPriceDifference={setShowPriceDifference}
            Icon={AirportIcon}
            title="Airport Transfer"
            data={data}
          />,
          document.getElementById('modal'),
        )}
    </>
  );
};

export default DetailOrder;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllShuttleAirport = createAsyncThunk(
  'shuttleAirport/getAllShuttleAirport',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { limit, currentPage = 1, locationId, search, pickupLocationId } = payload;

    let newUrl = `${BASE_URL}/${API_VERSION}/shuttle-airport?page=${currentPage}`;

    if (limit) {
      newUrl += `&limit=${limit}`;
    }

    if (locationId) {
      newUrl += `&location_id=${locationId}`;
    }

    if (search) {
      newUrl += `&search=${search}`;
    }

    if (pickupLocationId) {
      newUrl += `&pickup_location_id=${pickupLocationId}`;
    }

    try {
      const response = await axios.get(newUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getShuttleAirportZone = createAsyncThunk(
  'shuttleAirport/getShuttleAirportZone',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { limit, currentPage = 1, locationId, categoryIds, showSpecialAirportOnly } = payload;

    let newUrl = `${BASE_URL}/${API_VERSION}/shuttle-airport?page=${currentPage}&location_type=zone`;

    if (limit) {
      newUrl += `&limit=${limit}`;
    }

    if (locationId) {
      newUrl += `&location_id=${locationId}`;
    }

    if (categoryIds && categoryIds.length > 0) {
      newUrl += `&category_id=${categoryIds.join(',')}`;
    }

    if (showSpecialAirportOnly) {
      newUrl += `&show_special_airport_only=${showSpecialAirportOnly}`;
    }

    try {
      const response = await axios.get(newUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getShuttleTheAirport = createAsyncThunk(
  'shuttleAirport/getShuttleTheAirport',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { limit, currentPage = 1, locationId, categoryIds, showSpecialAirportOnly } = payload;

    let newUrl = `${BASE_URL}/${API_VERSION}/shuttle-airport?page=${currentPage}&location_type=airport`;

    if (limit) {
      newUrl += `&limit=${limit}`;
    }

    if (locationId) {
      newUrl += `&location_id=${locationId}`;
    }

    if (categoryIds && categoryIds.length > 0) {
      newUrl += `&category_id=${categoryIds.join(',')}`;
    }

    if (showSpecialAirportOnly) {
      newUrl += `&show_special_airport_only=${showSpecialAirportOnly}`;
    }

    try {
      const response = await axios.get(newUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

import React, { useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as AirportIcon } from 'icons/airport-transfer-filled.svg';
import { ReactComponent as PointsIcon } from 'icons/points-icon.svg';
import { Button, InputField, TableWrapper } from 'components/Global';
import { indonesianDateFormat } from 'utils/helpers';
import { setCurrency } from 'utils/functionality';
import clsx from 'clsx';
import TextAreaInput from 'components/Global/TextArea';
import { getPaymentMethodString } from 'utils/getPaymentMethod';
import Tabs, { TabPane } from 'components/Global/Tabs';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { downloadOrderDetail } from 'features/order-detail-pdf/actions';
import { getFileName } from 'utils/getFileName';
import { getLocationTimezone } from 'utils/getTimezone';
import { updateOrderCancelation } from 'features/orders/actions';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

function isAirportOrChangi(str) {
  return /airport|changi/i.test(str);
}

function formatPassengerCount(adult, child) {
  const parts = [];

  if (adult > 0) {
    parts.push(`${adult} Adult${adult > 1 ? 's' : ''}`);
  }

  if (child > 0) {
    parts.push(`${child} Child${child > 1 ? 'ren' : ''}`);
  }

  return parts.join(', ');
}

function formatBaggageCount(largeSuitcase, regularSuitcase) {
  const parts = [];

  if (largeSuitcase > 0) {
    parts.push(`${largeSuitcase} Suitcase${largeSuitcase > 1 ? 's' : ''} XL`);
  }

  if (regularSuitcase > 0) {
    parts.push(`${regularSuitcase} Luggage`);
  }

  return parts.join(', ');
}

const SpecialOrderDetail = ({
  data,
  setShowPriceDifference,
  selectedCar,
  newOrderData,
  oldOrderData,
  customer,
  imagePreviewHandler,
  selectedCountry,
  setShowBankTransferConfirmation,
  isPaymentValid,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast, setShowRejectOrderModal, setShowBroadcastTask, setShowConfirmation, setShowSpinner } =
    useAppContext();

  const [activeTab, setActiveTab] = useState('Data Sekarang');

  const airportPackageVehicle = useSelector((state) => state.airportTransferOrder.selected);
  const vehicleById = useSelector((state) => state.vehicle.selected);

  const backHandler = () => {
    navigate(-1);
  };

  const handleExportToPdf = async () => {
    try {
      await dispatch(downloadOrderDetail(data?.transaction_key)).unwrap();
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal Export ke PDF' });
    }
  };

  const handleRequestCancelOrder = () => {
    const payload = {
      transaction_key: data.transaction_key,
      status: 'REFUNDED',
    };

    setShowConfirmation({
      message: 'Apakah anda yakin ingin membatalkan pesanan ini?',
      show: true,
      onClick: async () => {
        setShowRejectOrderModal(false);

        try {
          setShowSpinner(true);
          await dispatch(updateOrderCancelation(payload)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Membatalkan Pesanan' });
          navigate(-1);
        } catch (err) {
          showToast({ type: 'error', message: 'Gagal Membatalkan Pesanan!' });
        } finally {
          setShowSpinner(false);
        }
      },
    });
  };

  const timezone = !data?.order_detail?.loc_time_id ? '' : getLocationTimezone(data?.order_detail?.loc_time_id);

  const checkIsDisable = (tasks) => {
    if (!tasks) return;

    if (tasks.length > 1) {
      const nonCancelItems = tasks.filter((item) => item.string !== 'CANCEL');

      if (nonCancelItems.length >= 2) {
        const sameTypes = new Set(nonCancelItems.map((item) => item.task_type));
        return sameTypes.size > 1;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isPaidOrder = data?.order_status === 'PAID';
  const isNotApproved = !data?.order_approval_status;
  const hasNoDriverTasks =
    data?.order_driver_tasks !== null &&
    Array.isArray(data?.order_driver_tasks) &&
    data?.order_driver_tasks.length === 0;

  const isTaskCancelled = data?.order_driver_tasks?.[0]?.status === 'CANCEL';
  const isOrderCancelled = data?.order_status === 'CANCELLED';

  const showPublishButton =
    (isPaidOrder && isNotApproved && hasNoDriverTasks) || (isTaskCancelled && !isOrderCancelled);

  const showCancelReason =
    (data.order_status === 'REQUEST_CANCEL' || data.order_status === 'CANCELLED') && data.order_cancelation !== null;

  return (
    <div className="detail-order-airport">
      {showPublishButton && (
        <Button
          className="detail-order-airport__publish-btn"
          style={{
            backgroundColor: checkIsDisable(data?.order_driver_tasks) ? '#e0e0e0' : '#007A05',
          }}
          onClick={() => setShowBroadcastTask(true)}
          disabled={checkIsDisable(data?.order_driver_tasks)}
        >
          Publish Task to Driver
        </Button>
      )}

      <div className="detail-order-airport__back-btn" onClick={backHandler}>
        <LeftArrow />
        <p>Kembali</p>
      </div>
      <TableWrapper
        icon={<AirportIcon fill="#009EF7" width="25px" height="25px" />}
        title="Airport Transfer"
        showExportButton
        onExportToPdf={handleExportToPdf}
        CustomFilterComponent={
          Object.prototype.hasOwnProperty.call(data, 'order_approval_history') ? (
            <div className="btn-export">
              <Button variant="primary" onClick={() => setShowPriceDifference(true)}>
                Detail Perbedaan
              </Button>
            </div>
          ) : null
        }
      >
        {data?.order_approval_status?.includes('UPDATE_ORDER') ? (
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
            <TabPane label="Data Sekarang">
              <div className="detail-order-airport__renter-detail">
                <div className="detail-order-airport__renter-detail-title">
                  <h1>Detail Penyewa</h1>
                </div>
                <div className="detail-order-airport__renter-detail-body">
                  <InputField label="Nama Lengkap" disabled value={newOrderData?.user_name || '-'} />
                  <InputField label="Email" disabled value={newOrderData?.email || '-'} />

                  <div className="detail-order-airport__renter-detail-body__phone-number">
                    <InputField
                      label="No Handphone"
                      disabled
                      value={
                        newOrderData?.is_admin_creation ? newOrderData?.phone_country_code : customer?.phone_code || '-'
                      }
                      icon={
                        <img
                          src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                          width={26}
                          height={16}
                          alt={selectedCountry?.name + ' nation flag'}
                        />
                      }
                    />
                    <InputField value={newOrderData?.phone_number || '-'} disabled />
                  </div>

                  <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                  <div className="detail-order-airport__renter-detail-body__phone-number">
                    <div className="detail-order-airport__renter-detail-body__phone-number-wrapper">
                      <InputField
                        label="Whatsapp"
                        disabled
                        value={
                          newOrderData?.is_admin_creation
                            ? newOrderData?.phone_country_code
                            : customer?.phone_code || '-'
                        }
                        icon={
                          <img
                            src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                            width={26}
                            height={16}
                            alt={selectedCountry?.name + ' nation flag'}
                          />
                        }
                      />
                      <div className="detail-order-airport__renter-detail-body__image-wrapper"></div>
                    </div>
                    <InputField disabled value={newOrderData?.wa_number || '-'} />
                  </div>
                </div>

                <div className="detail-order-airport__identity-detail-body">
                  <div className="detail-order-airport__identity-detail-body__preview-image">
                    <InputField
                      label="KTP"
                      disabled
                      value={
                        newOrderData?.is_admin_creation && getFileName(newOrderData?.order_detail?.identity?.ktp) !== ''
                          ? getFileName(newOrderData?.order_detail?.identity?.ktp)
                          : customer?.PersonalInfos?.ktp
                          ? getFileName(customer?.PersonalInfos?.ktp)
                          : 'Belum Upload KTP'
                      }
                      className="preview-image-input"
                    />
                    {(newOrderData?.is_admin_creation &&
                      getFileName(newOrderData?.order_detail?.identity?.ktp !== '')) ||
                    customer?.PersonalInfos?.ktp ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            newOrderData?.is_admin_creation
                              ? newOrderData?.order_detail?.identity?.ktp
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>

                  <div className="detail-order-airport__identity-detail-body__preview-image">
                    <InputField
                      label="SIM"
                      disabled
                      value={
                        newOrderData?.is_admin_creation && getFileName(newOrderData?.order_detail?.identity?.sim) !== ''
                          ? getFileName(newOrderData?.order_detail?.identity?.sim)
                          : customer?.PersonalInfos?.sim
                          ? getFileName(customer?.PersonalInfos?.sim)
                          : 'Belum Upload SIM'
                      }
                      className="preview-image-input"
                    />
                    {(newOrderData?.is_admin_creation &&
                      getFileName(newOrderData?.order_detail?.identity?.sim !== '')) ||
                    customer?.PersonalInfos?.sim ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            newOrderData?.is_admin_creation
                              ? newOrderData?.order_detail?.identity?.sim
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="detail-order-airport__rent-detail">
                <div className="detail-order-airport__rent-detail-title">
                  <h1>Detail Order</h1>
                </div>
                <div className="detail-order-airport__rent-detail-body">
                  <InputField
                    label="Lokasi Penjemputan"
                    disabled
                    value={newOrderData?.order_detail?.rental_delivery_location || '-'}
                  />
                  <InputField
                    label="Lokasi Pengantaran"
                    disabled
                    value={newOrderData?.order_detail?.rental_return_location || '-'}
                  />
                  <InputField
                    label="Detail Lokasi Penjemputan"
                    disabled
                    value={newOrderData?.order_detail?.rental_delivery_location_detail || '-'}
                  />
                  <InputField
                    label="Detail Lokasi Pengantaran"
                    disabled
                    value={newOrderData?.order_detail?.rental_return_location_detail || '-'}
                  />
                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Tanggal"
                      disabled
                      value={
                        newOrderData?.order_detail?.start_booking_date
                          ? indonesianDateFormat(newOrderData?.order_detail?.start_booking_date)
                          : '-'
                      }
                    />
                    <InputField
                      label="Jam"
                      disabled
                      value={(newOrderData?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                    />
                  </div>
                  <InputField
                    label="Nomor Penerbangan"
                    disabled
                    value={newOrderData?.order_detail?.flight_number || '-'}
                  />
                  <TextAreaInput
                    style={{
                      height:
                        newOrderData?.airport_package_vehicle_type?.split(', ').length >= 10
                          ? 96
                          : newOrderData?.airport_package_vehicle_type?.split(', ').length >= 5
                          ? 44 * 2
                          : 44,
                    }}
                    label="Jenis Mobil"
                    disabled
                    value={newOrderData?.airport_package_vehicle_type ?? '-'}
                    className="text-area-car-package"
                  />

                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Kapasitas Koper"
                      disabled
                      value={
                        newOrderData?.order_status === 'PENDING' || newOrderData?.order_status === 'CHECKOUT'
                          ? airportPackageVehicle?.max_suitecase
                          : newOrderData?.order_status === 'PAID' && !selectedCar
                          ? airportPackageVehicle?.max_suitecase
                          : vehicleById?.max_suitcase ?? '-'
                      }
                    />
                    <InputField label="Koper yang dibawa" disabled value={newOrderData?.order_detail?.baggage ?? 0} />
                  </div>
                </div>
              </div>

              <div className="detail-order-airport__price-detail">
                <div className="detail-order-airport__price-detail-title">
                  <h1>Rincian Biaya</h1>
                </div>

                <div className="detail-order-airport__price-detail-body">
                  <InputField
                    label="Biaya Sewa Mobil"
                    disabled
                    value={setCurrency(newOrderData?.booking_price) || '-'}
                  />
                  <InputField
                    label="Biaya Pengantaran"
                    disabled
                    value={setCurrency(newOrderData?.rental_delivery_fee) || '-'}
                  />
                  {newOrderData?.outside_operational_charge > 0 && (
                    <InputField
                      label="Outside Operational Hour Charge"
                      disabled
                      value={setCurrency(newOrderData?.outside_operational_charge) || '-'}
                    />
                  )}
                  <InputField
                    label="Pembayaran Dengan Point"
                    disabled
                    value={newOrderData?.point}
                    icon={<PointsIcon />}
                    iconPosition="start"
                  />
                  <InputField
                    label="Charge Payment Gateway Platform"
                    disabled
                    value={setCurrency(newOrderData?.disbursement?.total_amount_external_payment_fee) || '-'}
                  />
                  <InputField
                    label="Customer Service Platform"
                    disabled
                    value={setCurrency(newOrderData?.disbursement?.total_amount_customer_service_fee) || '-'}
                  />
                  <InputField
                    label="Total Keseluruhan"
                    disabled
                    value={setCurrency(newOrderData?.total_payment) || '-'}
                  />
                </div>
              </div>

              <div className="detail-order__voucher-detail">
                <div className="detail-order__voucher-detail-title">
                  <h1>Voucher</h1>
                </div>
                <div className="detail-order__voucher-detail-body">
                  <InputField label="Nama Voucher" disabled value={newOrderData?.vouchers?.[0].name || '-'} />
                  <InputField label="Kode Voucher" disabled value={newOrderData?.vouchers?.[0].code || '-'} />
                  <InputField
                    label="Harga Potongan"
                    disabled
                    value={setCurrency(newOrderData?.vouchers?.[0].value) || '-'}
                  />
                </div>
              </div>

              <div className="detail-order-airport__payment-detail">
                <div className="detail-order-airport__payment-detail-title">
                  <h1>Pembayaran</h1>
                </div>
                <div className="detail-order-airport__payment-detail-body">
                  <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                  <div className={clsx('detail-order-airport__payment-detail-body__payment-method confirmation')}>
                    <InputField
                      label="Metode Pembayaran"
                      disabled
                      // value={data?.disbursement?.payment?.method ? data?.disbursement.payment.method : '-'}
                      value={getPaymentMethodString(data?.disbursement_recalculate)}
                    />
                    {data.order_status === 'CHECKOUT' &&
                    data?.order_approval_status !== 'WAITING_APPROVAL_DELETE_ORDER' ? (
                      <Button
                        height={44}
                        onClick={() => setShowBankTransferConfirmation(true)}
                        disabled={isPaymentValid}
                      >
                        Konfirmasi
                      </Button>
                    ) : null}
                  </div>

                  {data.order_status !== 'CHECKOUT' &&
                  data.order_status !== 'RECONFIRMATION' &&
                  Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                    <>
                      <InputField
                        label="Nama Rekening"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_name
                            : data?.disbursement?.sender_name || '-'
                        }
                      />

                      <InputField
                        label="Nama Bank"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                            : data?.disbursement?.sender_bank_name || '-'
                        }
                      />

                      <div className="detail-order__payment-detail-body__preview-image">
                        <InputField
                          label="Foto Bukti Transfer"
                          disabled
                          value={getFileName(
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                              : data?.disbursement?.disbursement_confirmation_image,
                          )}
                          className="preview-image-input"
                        />

                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                : data?.disbursement?.disbursement_confirmation_image,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      </div>
                      <div />
                    </>
                  ) : null}
                </div>
              </div>

              <div className="detail-order-airport__transaction-buttons">
                <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                  Kembali
                </Button>
              </div>
            </TabPane>
            <TabPane label="Data Sebelum">
              <div className="detail-order-airport__renter-detail">
                <div className="detail-order-airport__renter-detail-title">
                  <h1>Detail Penyewa</h1>
                </div>
                <div className="detail-order-airport__renter-detail-body">
                  <InputField label="Nama Lengkap" disabled value={oldOrderData?.user_name || '-'} />
                  <InputField label="Email" disabled value={oldOrderData?.email || '-'} />

                  <div className="detail-order-airport__renter-detail-body__phone-number">
                    <InputField
                      label="No Handphone"
                      disabled
                      value={
                        oldOrderData?.is_admin_creation ? oldOrderData?.phone_country_code : customer?.phone_code || '-'
                      }
                      icon={
                        <img
                          src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                          width={26}
                          height={16}
                          alt={selectedCountry?.name + ' nation flag'}
                        />
                      }
                    />
                    <InputField value={oldOrderData?.phone_number || '-'} disabled />
                  </div>

                  <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                  <div className="detail-order-airport__renter-detail-body__phone-number">
                    <div className="detail-order-airport__renter-detail-body__phone-number-wrapper">
                      <InputField
                        label="Whatsapp"
                        disabled
                        value={
                          oldOrderData?.is_admin_creation
                            ? oldOrderData?.phone_country_code
                            : customer?.phone_code || '-'
                        }
                        icon={
                          <img
                            src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                            width={26}
                            height={16}
                            alt={selectedCountry?.name + ' nation flag'}
                          />
                        }
                      />
                      <div className="detail-order-airport__renter-detail-body__image-wrapper"></div>
                    </div>
                    <InputField disabled value={oldOrderData?.wa_number || '-'} />
                  </div>
                </div>

                <div className="detail-order-airport__identity-detail-body">
                  <div className="detail-order-airport__identity-detail-body__preview-image">
                    <InputField
                      label="KTP"
                      disabled
                      value={
                        oldOrderData?.is_admin_creation && getFileName(oldOrderData?.order_detail?.identity?.ktp) !== ''
                          ? getFileName(oldOrderData?.order_detail?.identity?.ktp)
                          : customer?.PersonalInfos?.ktp
                          ? getFileName(customer?.PersonalInfos?.ktp)
                          : 'Belum Upload KTP'
                      }
                      className="preview-image-input"
                    />
                    {(oldOrderData?.is_admin_creation &&
                      getFileName(oldOrderData?.order_detail?.identity?.ktp !== '')) ||
                    customer?.PersonalInfos?.ktp ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            oldOrderData?.is_admin_creation
                              ? oldOrderData?.order_detail?.identity?.ktp
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>

                  <div className="detail-order-airport__identity-detail-body__preview-image">
                    <InputField
                      label="SIM"
                      disabled
                      value={
                        oldOrderData?.is_admin_creation && getFileName(oldOrderData?.order_detail?.identity?.sim) !== ''
                          ? getFileName(oldOrderData?.order_detail?.identity?.sim)
                          : customer?.PersonalInfos?.sim
                          ? getFileName(customer?.PersonalInfos?.sim)
                          : 'Belum Upload SIM'
                      }
                      className="preview-image-input"
                    />
                    {(oldOrderData?.is_admin_creation &&
                      getFileName(oldOrderData?.order_detail?.identity?.sim !== '')) ||
                    customer?.PersonalInfos?.sim ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            oldOrderData?.is_admin_creation
                              ? oldOrderData?.order_detail?.identity?.sim
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="detail-order-airport__rent-detail">
                <div className="detail-order-airport__rent-detail-title">
                  <h1>Detail Order</h1>
                </div>
                <div className="detail-order-airport__rent-detail-body">
                  <InputField
                    label="Lokasi Penjemputan"
                    disabled
                    value={oldOrderData?.order_detail?.rental_delivery_location || '-'}
                  />
                  <InputField
                    label="Lokasi Pengantaran"
                    disabled
                    value={oldOrderData?.order_detail?.rental_return_location || '-'}
                  />
                  <InputField
                    label="Detail Lokasi Penjemputan"
                    disabled
                    value={oldOrderData?.order_detail?.rental_delivery_location_detail || '-'}
                  />
                  <InputField
                    label="Detail Lokasi Pengantaran"
                    disabled
                    value={oldOrderData?.order_detail?.rental_return_location_detail || '-'}
                  />
                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Tanggal"
                      disabled
                      value={
                        oldOrderData?.order_detail?.start_booking_date
                          ? indonesianDateFormat(oldOrderData?.order_detail?.start_booking_date)
                          : '-'
                      }
                    />
                    <InputField
                      label="Jam"
                      disabled
                      value={(oldOrderData?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                    />
                  </div>
                  <InputField
                    label="Nomor Penerbangan"
                    disabled
                    value={oldOrderData?.order_detail?.flight_number || '-'}
                  />
                  <TextAreaInput
                    style={{
                      height:
                        oldOrderData?.airport_package_vehicle_type?.split(', ').length >= 10
                          ? 96
                          : oldOrderData?.airport_package_vehicle_type?.split(', ').length >= 5
                          ? 44 * 2
                          : 44,
                    }}
                    label="Jenis Mobil"
                    disabled
                    value={oldOrderData?.airport_package_vehicle_type ?? '-'}
                    className="text-area-car-package"
                  />

                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Kapasitas Koper"
                      disabled
                      value={
                        oldOrderData?.order_status === 'PENDING' || oldOrderData?.order_status === 'CHECKOUT'
                          ? airportPackageVehicle?.max_suitecase
                          : oldOrderData?.order_status === 'PAID' && !selectedCar
                          ? airportPackageVehicle?.max_suitecase
                          : vehicleById?.max_suitcase ?? '-'
                      }
                    />
                    <InputField label="Koper yang dibawa" disabled value={oldOrderData?.order_detail?.baggage ?? 0} />
                  </div>
                </div>
              </div>

              <div className="detail-order-airport__price-detail">
                <div className="detail-order-airport__price-detail-title">
                  <h1>Rincian Biaya</h1>
                </div>

                <div className="detail-order-airport__price-detail-body">
                  <InputField
                    label="Biaya Sewa Mobil"
                    disabled
                    value={setCurrency(oldOrderData?.booking_price) || '-'}
                  />
                  <InputField
                    label="Biaya Pengantaran"
                    disabled
                    value={setCurrency(oldOrderData?.rental_delivery_fee) || '-'}
                  />
                  {oldOrderData?.outside_operational_charge > 0 && (
                    <InputField
                      label="Outside Operational Hour Charge"
                      disabled
                      value={setCurrency(oldOrderData?.outside_operational_charge) || '-'}
                    />
                  )}
                  <InputField
                    label="Pembayaran Dengan Point"
                    disabled
                    value={oldOrderData?.point}
                    icon={<PointsIcon />}
                    iconPosition="start"
                  />
                  <InputField
                    label="Charge Payment Gateway Platform"
                    disabled
                    value={setCurrency(oldOrderData?.disbursement?.total_amount_external_payment_fee) || '-'}
                  />
                  <InputField
                    label="Customer Service Platform"
                    disabled
                    value={setCurrency(oldOrderData?.disbursement?.total_amount_customer_service_fee) || '-'}
                  />
                  <InputField
                    label="Total Keseluruhan"
                    disabled
                    value={setCurrency(oldOrderData?.total_payment) || '-'}
                  />
                </div>
              </div>

              <div className="detail-order__voucher-detail">
                <div className="detail-order__voucher-detail-title">
                  <h1>Voucher</h1>
                </div>
                <div className="detail-order__voucher-detail-body">
                  <InputField label="Nama Voucher" disabled value={oldOrderData?.vouchers?.[0].name || '-'} />
                  <InputField label="Kode Voucher" disabled value={oldOrderData?.vouchers?.[0].code || '-'} />
                  <InputField
                    label="Harga Potongan"
                    disabled
                    value={setCurrency(oldOrderData?.vouchers?.[0].value) || '-'}
                  />
                </div>
              </div>

              <div className="detail-order-airport__payment-detail">
                <div className="detail-order-airport__payment-detail-title">
                  <h1>Pembayaran</h1>
                </div>
                <div className="detail-order-airport__payment-detail-body">
                  <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                  <div
                    className={clsx(
                      'detail-order-airport__payment-detail-body__payment-method',
                      data.order_status === 'CHECKOUT' ? 'confirmation' : 'not-confirmation',
                    )}
                  >
                    <InputField
                      label="Metode Pembayaran"
                      disabled
                      // value={data?.disbursement?.payment?.method ? data?.disbursement.payment.method : '-'}
                      value={getPaymentMethodString(data.disbursement)}
                    />
                  </div>

                  {data.order_status !== 'CHECKOUT' &&
                  data.order_status !== 'RECONFIRMATION' &&
                  Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                    <>
                      <InputField
                        label="Nama Rekening"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_name
                            : data?.disbursement?.sender_name || '-'
                        }
                      />

                      <InputField
                        label="Nama Bank"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                            : data?.disbursement?.sender_bank_name || '-'
                        }
                      />

                      <div className="detail-order__payment-detail-body__preview-image">
                        <InputField
                          label="Foto Bukti Transfer"
                          disabled
                          value={getFileName(
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                              : data?.disbursement?.disbursement_confirmation_image,
                          )}
                          className="preview-image-input"
                        />

                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                : data?.disbursement?.disbursement_confirmation_image,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      </div>
                      <div />
                    </>
                  ) : null}
                </div>
              </div>

              <div className="detail-order-airport__transaction-buttons">
                <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                  Kembali
                </Button>
              </div>
            </TabPane>
          </Tabs>
        ) : (
          <>
            <div className="detail-order-airport__renter-detail">
              <div className="detail-order-airport__renter-detail-title">
                <h1>Detail Order</h1>
              </div>
              <div className="detail-order-airport__renter-detail-body">
                <InputField label="Nama Customer" disabled value={data?.user_name || '-'} />
                <div className="detail-order-airport__renter-detail-body__phone-number">
                  <InputField
                    label="Whatsapp"
                    disabled
                    value={data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code || '-'}
                    icon={
                      <img
                        src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                        width={26}
                        height={16}
                        alt={selectedCountry?.name + ' nation flag'}
                      />
                    }
                  />
                  <InputField value={data?.wa_number || '-'} disabled />
                </div>
                <InputField
                  label="Tanggal"
                  disabled
                  value={
                    data?.order_detail?.start_booking_date
                      ? indonesianDateFormat(data?.order_detail?.start_booking_date)
                      : '-'
                  }
                />
                <InputField
                  label="Jam"
                  disabled
                  value={(data?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                />
                <div className="detail-order__rent-detail-body__rent-date">
                  <InputField
                    label="Penumpang"
                    disabled
                    value={
                      formatPassengerCount(data?.order_detail?.adult_passenger, data?.order_detail?.child_passenger) ||
                      '-'
                    }
                  />
                  <InputField
                    label="Koper"
                    disabled
                    value={
                      formatBaggageCount(data?.order_detail?.large_suitcase, data?.order_detail?.regular_suitcase) ||
                      '-'
                    }
                  />
                </div>
                <InputField label="Flight Number" disabled value={data?.order_detail?.flight_number || '-'} />
                <InputField label="Pick Up" disabled value={data?.order_detail?.rental_delivery_location || '-'} />
                <InputField label="Drop Off" disabled value={data?.order_detail?.rental_return_location || '-'} />
                <InputField
                  label="Detail Lokasi"
                  disabled
                  value={data?.order_detail?.rental_delivery_location_detail || '-'}
                />
                <InputField
                  label="Detail Lokasi"
                  disabled
                  value={data?.order_detail?.rental_return_location_detail || '-'}
                />
                <InputField label="Kategori Mobil" disabled value={data?.order_detail?.vehicle.name || '-'} />

                {isAirportOrChangi(data?.order_detail?.rental_delivery_location) ? (
                  <InputField label="Meet and Greet Board" disabled value={data?.customer_name_meet_and_greet || '-'} />
                ) : null}
              </div>
            </div>

            <div className="detail-order-airport__price-detail">
              <div className="detail-order-airport__price-detail-title">
                <h1>Rincian Biaya</h1>
              </div>

              <div className="detail-order-airport__renter-detail-body">
                <InputField
                  label="Biaya Order"
                  disabled
                  value={setCurrency(data?.total_amount_order, data?.currency) || '-'}
                />
                <InputField label="Fee Driver" disabled value={setCurrency(data?.fee_driver, data?.currency) || '-'} />
                <InputField
                  label="Total Biaya"
                  disabled
                  value={setCurrency(data?.total_amount_order, data?.currency) || '-'}
                />

                {data?.fee_getnride > 0 ? (
                  <InputField
                    label="Fee Get and Ride"
                    disabled
                    value={setCurrency(data?.fee_getnride, data?.currency) || '-'}
                  />
                ) : null}

                {data?.outside_operational_charge > 0 ? (
                  <InputField
                    label="Biaya Tambahan / Diluar jam Operasional"
                    disabled
                    value={setCurrency(data?.outside_operational_charge, data?.currency) || '-'}
                  />
                ) : null}
              </div>
            </div>

            <div className="detail-order-airport__payment-detail">
              <div className="detail-order-airport__payment-detail-title">
                <h1>Pembayaran</h1>
              </div>
              <div className="detail-order-airport__payment-detail-body">
                <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                <div
                  className={clsx(
                    'detail-order-airport__payment-detail-body__payment-method',
                    data.order_status === 'CHECKOUT' ? 'confirmation' : 'not-confirmation',
                  )}
                >
                  <InputField
                    label="Metode Pembayaran"
                    disabled
                    // value={data?.disbursement?.payment?.method ? data?.disbursement.payment.method : '-'}
                    value={getPaymentMethodString(data.disbursement)}
                  />
                  {data.order_status === 'CHECKOUT' &&
                  data?.order_approval_status !== 'WAITING_APPROVAL_DELETE_ORDER' ? (
                    <Button height={44} onClick={() => setShowBankTransferConfirmation(true)} disabled={isPaymentValid}>
                      Konfirmasi
                    </Button>
                  ) : null}
                </div>

                {/* {data.order_status !== 'CHECKOUT' &&
                data.order_status !== 'RECONFIRMATION' &&
                Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                  <>
                    <InputField
                      label="Nama Rekening"
                      disabled
                      value={
                        Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                          ? [...data.disbursement_reconfirmation].pop()?.sender_name
                          : data?.disbursement?.sender_name || '-'
                      }
                    />

                    <InputField
                      label="Nama Bank"
                      disabled
                      value={
                        Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                          ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                          : data?.disbursement?.sender_bank_name || '-'
                      }
                    />

                    <div className="detail-order__payment-detail-body__preview-image">
                      <InputField
                        label="Foto Bukti Transfer"
                        disabled
                        value={getFileName(
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                            : data?.disbursement?.disbursement_confirmation_image,
                        )}
                        className="preview-image-input"
                      />

                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                              : data?.disbursement?.disbursement_confirmation_image,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    </div>
                    <div />
                  </>
                ) : null} */}
              </div>
            </div>

            {showCancelReason ? (
              <div className="detail-order-airport__cancelation-reason">
                <div className="detail-order-airport__cancelation-reason-title">
                  <h1>Keterangan Lainnya</h1>
                </div>

                <div className="detail-order-airport__cancelation-reason-body">
                  <div className="detail-order-airport__cancelation-reason-message-area">
                    <label htmlFor="cancelation-reason">Alasan Pembatalan</label>
                    <textarea
                      name="cancelation-reason"
                      id=""
                      cols="30"
                      rows="10"
                      value={data.order_cancelation.cancelation_reason}
                      readOnly
                      disabled
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div className="detail-order-airport__transaction-buttons">
              <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                Kembali
              </Button>
              {(data.order_status === 'CHECKOUT' || data.order_status === 'REVIEWING_IDENTITY') &&
              !data.order_approval_status ? (
                <Button
                  size="sm"
                  className="button"
                  width={208}
                  variant="danger"
                  onClick={() => setShowRejectOrderModal(true)}
                >
                  Tolak Pesanan
                </Button>
              ) : null}

              {/* {(data.order_status === 'PAID' ||
                (data.order_status === 'REVIEWING_IDENTITY' &&
                  !customer?.PersonalInfos?.need_review_sim &&
                  !customer?.PersonalInfos?.need_review_ktp)) &&
              !data.order_approval_status ? (
                <Button variant="success" className="button" width={208} size="sm" onClick={handleProcess}>
                  Proses
                </Button>
              ) : null} */}

              <Button size="sm" className="button" width={208} variant="danger" onClick={handleRequestCancelOrder}>
                Cancel Order
              </Button>
            </div>
          </>
        )}
      </TableWrapper>
    </div>
  );
};

export default SpecialOrderDetail;

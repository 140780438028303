import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import WithoutDriverForm from './WithoutDriverForm';
import { useLocation } from 'react-router-dom';
import WithDriverForm from './WithDriverForm';
// import { useEffect } from 'react';
import AirportTransferForm from './AirportTransferForm';
import OneWayForm from './OneWayForm';

const DetailSewa = forwardRef(
  (
    {
      prevPage,
      nextPage,
      orderData,
      setOrderData,
      vehicleInput,
      setVehicleInput,
      selectedCar,
      setSelectedCar,
      startRentDate,
      setStartRentDate,
      endRentDate,
      setEndRentDate,
      deliveryLocation,
      setDeliveryLocation,
      returnLocation,
      setReturnLocation,
      isOrderConfirmation,
      // refferalCode,
      // setRefferalCode,
      driver,
      setDriver,
      allVehicle,
      selectedOvertime,
      setSelectedOvertime,
      selectedPaymentType,
      setSelectedPaymentType,
      // with driver//
      durationRent,
      setDurationRent,
      dayDuration,
      setDayDuration,
      allZonesList,
      orderDataLocalStorage,
      setOrderDataLocalStorage,
      // airport transfer//
      isSwitch,
      setIsSwitch,
      // shuttleData,
      rentalLocationId,
      setRentalLocationId,
      selectedAdditionalItem,
      setSelectedAdditionalItem,
      // descriptionAdditionalItem,
      // setDescriptionAdditionalItem,
      luggageAirport,
      setLuggageAirport,
      passengersAirport,
      setPassengersAirport,
      startLocation,
      setStartLocation,
      endLocation,
      setEndLocation,
      locationData,
      setLocationData,
    },
    ref,
  ) => {
    const grandChildRef = useRef(null);
    const location = useLocation();

    const pathName = location.pathname.split('/').filter((item) => item !== '')[0];

    // SCROLL TO TOP WHEN COMPONENT IS LOAD
    // useEffect(() => {
    //   window.scrollTo(0, 0);
    // }, []);

    useImperativeHandle(ref, () => ({
      callGrandChild() {
        if (grandChildRef.current) {
          grandChildRef.current.actionInGrandChild();
        }
      },
    }));

    return (
      <form className="detail-sewa">
        <div className="detail-sewa__detail-sewa-form">
          {pathName !== 'with-driver' ? (
            <span className="detail-sewa__detail-sewa-form__title">
              <h2>{pathName === 'one-way' ? 'Detail Order' : 'Detail Sewa'}</h2>
            </span>
          ) : null}

          {pathName === 'without-driver' ? (
            <WithoutDriverForm
              prevPage={prevPage}
              nextPage={nextPage}
              orderData={orderData}
              setOrderData={setOrderData}
              vehicleInput={vehicleInput}
              setVehicleInput={setVehicleInput}
              selectedCar={selectedCar}
              setSelectedCar={setSelectedCar}
              startRentDate={startRentDate}
              setStartRentDate={setStartRentDate}
              endRentDate={endRentDate}
              setEndRentDate={setEndRentDate}
              deliveryLocation={deliveryLocation}
              setDeliveryLocation={setDeliveryLocation}
              returnLocation={returnLocation}
              setReturnLocation={setReturnLocation}
              // refferalCode={refferalCode}
              // setRefferalCode={setRefferalCode}
              driver={driver}
              setDriver={setDriver}
              allVehicle={allVehicle}
              selectedOvertime={selectedOvertime}
              setSelectedOvertime={setSelectedOvertime}
              selectedPaymentType={selectedPaymentType}
              setSelectedPaymentType={setSelectedPaymentType}
              isOrderConfirmation={isOrderConfirmation}
              orderDataLocalStorage={orderDataLocalStorage}
              setOrderDataLocalStorage={setOrderDataLocalStorage}
              rentalLocationId={rentalLocationId}
              setRentalLocationId={setRentalLocationId}
              selectedAdditionalItem={selectedAdditionalItem}
              setSelectedAdditionalItem={setSelectedAdditionalItem}
              // descriptionAdditionalItem={descriptionAdditionalItem}
              // setDescriptionAdditionalItem={setDescriptionAdditionalItem}
              ref={grandChildRef}
            />
          ) : pathName === 'with-driver' ? (
            <WithDriverForm
              prevPage={prevPage}
              nextPage={nextPage}
              allVehicle={allVehicle}
              selectedCar={selectedCar}
              setSelectedCar={setSelectedCar}
              vehicleInput={vehicleInput}
              setVehicleInput={setVehicleInput}
              orderData={orderData}
              setOrderData={setOrderData}
              startRentDate={startRentDate}
              setStartRentDate={setStartRentDate}
              durationRent={durationRent}
              setDurationRent={setDurationRent}
              driver={driver}
              setDriver={setDriver}
              selectedOvertime={selectedOvertime}
              setSelectedOvertime={setSelectedOvertime}
              selectedPaymentType={selectedPaymentType}
              setSelectedPaymentType={setSelectedPaymentType}
              isOrderConfirmation={isOrderConfirmation}
              dayDuration={dayDuration}
              setDayDuration={setDayDuration}
              allZonesList={allZonesList}
              orderDataLocalStorage={orderDataLocalStorage}
              setOrderDataLocalStorage={setOrderDataLocalStorage}
              rentalLocationId={rentalLocationId}
              setRentalLocationId={setRentalLocationId}
              selectedAdditionalItem={selectedAdditionalItem}
              setSelectedAdditionalItem={setSelectedAdditionalItem}
              // descriptionAdditionalItem={descriptionAdditionalItem}
              // setDescriptionAdditionalItem={setDescriptionAdditionalItem}
              ref={grandChildRef}
            />
          ) : pathName === 'airport-transfer' ? (
            <AirportTransferForm
              prevPage={prevPage}
              nextPage={nextPage}
              orderData={orderData}
              setOrderData={setOrderData}
              deliveryLocation={deliveryLocation}
              setDeliveryLocation={setDeliveryLocation}
              returnLocation={returnLocation}
              setReturnLocation={setReturnLocation}
              isOrderConfirmation={isOrderConfirmation}
              startRentDate={startRentDate}
              setStartRentDate={setStartRentDate}
              setEndRentDate={setEndRentDate}
              allVehicle={allVehicle}
              selectedCar={selectedCar}
              setSelectedCar={setSelectedCar}
              vehicleInput={vehicleInput}
              setVehicleInput={setVehicleInput}
              isSwitch={isSwitch}
              setIsSwitch={setIsSwitch}
              // shuttleData={shuttleData}
              rentalLocationId={rentalLocationId}
              setRentalLocationId={setRentalLocationId}
              ref={grandChildRef}
              luggageAirport={luggageAirport}
              setLuggageAirport={setLuggageAirport}
              passengersAirport={passengersAirport}
              setPassengersAirport={setPassengersAirport}
              startLocation={startLocation}
              setStartLocation={setStartLocation}
              endLocation={endLocation}
              setEndLocation={setEndLocation}
              locationData={locationData}
              setLocationData={setLocationData}
            />
          ) : pathName === 'one-way' ? (
            <OneWayForm
              prevPage={prevPage}
              nextPage={nextPage}
              orderData={orderData}
              setOrderData={setOrderData}
              isOrderConfirmation={isOrderConfirmation}
              startRentDate={startRentDate}
              setStartRentDate={setStartRentDate}
              setEndRentDate={setEndRentDate}
              rentalLocationId={rentalLocationId}
              setRentalLocationId={setRentalLocationId}
              startLocation={startLocation}
              setStartLocation={setStartLocation}
              endLocation={endLocation}
              setEndLocation={setEndLocation}
              locationData={locationData}
              setLocationData={setLocationData}
              ref={grandChildRef}
            />
          ) : null}
        </div>
      </form>
    );
  },
);

DetailSewa.displayName = 'Detail Sewa';
export default DetailSewa;

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllRentalLocation,
  getRentalLocationById,
  updateRentalLocation,
  getRentalLocationByServices,
} from './actions';

const initialState = {
  data: [],
  selected: {},
  isLoading: false,
  isError: false,
  errorMessage: '', // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const rentalLocation = createSlice({
  name: 'rentalLocation',
  initialState,
  reducers: {
    // reducers goes here
    selectRentalLocation: (state, action) => {
      return {
        ...state,
        selected: action.payload,
      };
    },
    resetSelectedLocation: (state) => {
      return {
        ...state,
        selected: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRentalLocation.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
        state.data = [];
      })
      .addCase(getAllRentalLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllRentalLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getRentalLocationByServices.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getRentalLocationByServices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getRentalLocationByServices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getRentalLocationById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
        state.selected = {};
      })
      .addCase(getRentalLocationById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getRentalLocationById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selected = action.payload;
      })
      .addCase(updateRentalLocation.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(updateRentalLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateRentalLocation.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export const { selectRentalLocation, resetSelectedLocation } = rentalLocation.actions;

export default rentalLocation.reducer;

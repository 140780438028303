import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as LuggageIcon } from 'icons/luggage-icon.svg';
import { ReactComponent as AdultsIcon } from 'icons/adults-icon.svg';
import { ReactComponent as SuitcaseXlIcon } from 'icons/suitcase-xl-icon.svg';
import { ReactComponent as ChildIcon } from 'icons/child-icon.svg';
import { ReactComponent as PlusIcon } from 'icons/plus-circle-filled-icon.svg';
import { ReactComponent as MinusIcon } from 'icons/minus-circle-filled-icon.svg';

const SelectFieldPassengerAndLuggage = ({
  label,
  htmlFor,
  className,
  style,
  selectedPassengers = { child: 0, adults: 0 },
  selectedLuggage = { luggage: 0, suitcaseXl: 0 },
  onSelect,
  disable,
  ...rest
}) => {
  const dropdownRef = useRef(null);
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the dropdown.
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts.
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsShowDropdown(!isShowDropdown);
  };

  const selectLuggageHandler = (type, operation) => {
    if (type === 'luggage') {
      if (operation === 'increase') {
        onSelect(selectedPassengers, {
          luggage: selectedLuggage?.luggage + 1,
          suitcaseXl: selectedLuggage?.suitcaseXl,
        });
      } else if (operation === 'decrease') {
        onSelect(selectedPassengers, {
          luggage: selectedLuggage?.luggage - 1,
          suitcaseXl: selectedLuggage?.suitcaseXl,
        });
      }
    } else if (type === 'suitcaseXl') {
      if (operation === 'increase') {
        onSelect(selectedPassengers, {
          luggage: selectedLuggage?.luggage,
          suitcaseXl: selectedLuggage?.suitcaseXl + 1,
        });
      } else if (operation === 'decrease') {
        onSelect(selectedPassengers, {
          luggage: selectedLuggage?.luggage,
          suitcaseXl: selectedLuggage?.suitcaseXl - 1,
        });
      }
    }
  };

  const selectPassengersHandler = (type, operation) => {
    const totalPassengers = selectedPassengers.adults + selectedPassengers.child;
    const totalLuggage = selectedLuggage.luggage + selectedLuggage.suitcaseXl;

    if (type === 'adults') {
      if (operation === 'increase') {
        onSelect(
          {
            adults: selectedPassengers?.adults + 1,
            child: selectedPassengers?.child,
          },
          (totalPassengers === 3 || totalPassengers === 4 || totalPassengers === 5) && totalLuggage !== 0
            ? { luggage: 1, suitcaseXl: 1 }
            : selectedLuggage,
        );
      } else if (operation === 'decrease') {
        onSelect(
          {
            adults: selectedPassengers?.adults - 1,
            child: selectedPassengers?.child,
          },
          (totalPassengers === 3 || totalPassengers === 4 || totalPassengers === 5) && totalLuggage !== 0
            ? { luggage: 1, suitcaseXl: 1 }
            : selectedLuggage,
        );
      }
    } else if (type === 'child') {
      if (operation === 'increase') {
        onSelect(
          {
            adults: selectedPassengers?.adults,
            child: selectedPassengers?.child + 1,
          },
          (totalPassengers === 4 || totalPassengers === 5 || totalPassengers === 6) && totalLuggage !== 0
            ? { luggage: 1, suitcaseXl: 1 }
            : selectedLuggage,
        );
      } else if (operation === 'decrease') {
        onSelect(
          {
            adults: selectedPassengers?.adults,
            child: selectedPassengers?.child - 1,
          },
          (totalPassengers === 4 || totalPassengers === 5 || totalPassengers === 6) && totalLuggage !== 0
            ? { luggage: 1, suitcaseXl: 1 }
            : selectedLuggage,
        );
      }
    }
  };

  const maxDisableClass = (type) => {
    const totalPassengers = selectedPassengers.adults + selectedPassengers.child;
    const totalLuggage = selectedLuggage.luggage + selectedLuggage.suitcaseXl;

    if (type === 'adults' || type === 'child') {
      return totalPassengers < 6 ? '' : 'disable';
    }

    if (type === 'luggage') {
      if (totalPassengers <= 3) {
        return totalLuggage < 5 && selectedLuggage.luggage < 3 ? '' : 'disable';
      } else if (totalPassengers > 3 && totalPassengers < 5) {
        return totalLuggage < 4 ? '' : 'disable';
      } else if (totalPassengers > 4 && totalPassengers < 6) {
        return totalLuggage < 3 ? '' : 'disable';
      } else if (totalPassengers > 5) {
        return totalLuggage < 2 ? '' : 'disable';
      }
    } else if (type === 'suitcaseXl') {
      if (totalPassengers <= 3) {
        return totalLuggage < 5 && selectedLuggage.suitcaseXl < 2 ? '' : 'disable';
      } else if (totalPassengers > 3 && totalPassengers < 5) {
        return totalLuggage < 4 && selectedLuggage.suitcaseXl < 2 ? '' : 'disable';
      } else if (totalPassengers > 4 && totalPassengers < 6) {
        return totalLuggage < 3 && selectedLuggage.suitcaseXl < 2 ? '' : 'disable';
      } else if (totalPassengers > 5) {
        return totalLuggage < 2 && selectedLuggage.suitcaseXl < 2 ? '' : 'disable';
      }
    }

    return '';
  };

  return (
    <div className={clsx('select-field-passenger-and-luggage', className)} style={style} {...rest} ref={dropdownRef}>
      {label && (
        <label
          htmlFor={htmlFor}
          className="select-field-passenger-and-luggage__label"
          style={{ pointerEvents: disable ? 'none' : 'default' }}
          onClick={toggleDropdown}
        >
          {label}
        </label>
      )}
      <div
        className="select-field-passenger-and-luggage__field"
        style={{ pointerEvents: disable ? 'none' : 'default', background: disable ? '#f5f5f5' : '' }}
        onClick={toggleDropdown}
      >
        <LuggageIcon />
        <p>{selectedLuggage?.luggage + selectedLuggage?.suitcaseXl}</p>
        <AdultsIcon />
        <p>{selectedPassengers?.adults + selectedPassengers?.child}</p>
      </div>

      {isShowDropdown && (
        <ul className="select-field-passenger-and-luggage__dropdown">
          <li className="select-field-passenger-and-luggage__dropdown__list">
            <div>
              <LuggageIcon />
              <p>Luggage</p>
            </div>
            <div className="select-field-passenger-and-luggage__dropdown__list__operations">
              <button
                type="button"
                className={clsx('button-operation', selectedLuggage?.luggage == 0 ? 'disable' : '')}
                onClick={() => selectLuggageHandler('luggage', 'decrease')}
              >
                <MinusIcon className="counter-icon" />
              </button>
              <span>{selectedLuggage?.luggage || 0}</span>
              <button
                type="button"
                className={clsx('button-operation', maxDisableClass('luggage'))}
                onClick={() => selectLuggageHandler('luggage', 'increase')}
              >
                <PlusIcon className="counter-icon" />
              </button>
            </div>
          </li>
          <li className="select-field-passenger-and-luggage__dropdown__list">
            <div>
              <SuitcaseXlIcon />
              <p>Suitcase XL</p>
            </div>
            <div className="select-field-passenger-and-luggage__dropdown__list__operations">
              <button
                type="button"
                className={clsx('button-operation', selectedLuggage?.suitcaseXl == 0 ? 'disable' : '')}
                onClick={() => selectLuggageHandler('suitcaseXl', 'decrease')}
              >
                <MinusIcon className="counter-icon" />
              </button>
              <span>{selectedLuggage?.suitcaseXl || 0}</span>
              <button
                type="button"
                className={clsx('button-operation', maxDisableClass('suitcaseXl'))}
                onClick={() => selectLuggageHandler('suitcaseXl', 'increase')}
              >
                <PlusIcon className="counter-icon" />
              </button>
            </div>
          </li>
          <li className="select-field-passenger-and-luggage__dropdown__list">
            <div>
              <AdultsIcon />
              <p>Adults</p>
            </div>
            <div className="select-field-passenger-and-luggage__dropdown__list__operations">
              <button
                type="button"
                className={clsx('button-operation', selectedPassengers?.adults == 0 ? 'disable' : '')}
                onClick={() => selectPassengersHandler('adults', 'decrease')}
              >
                <MinusIcon className="counter-icon" />
              </button>
              <span>{selectedPassengers?.adults || 0}</span>
              <button
                type="button"
                className={clsx('button-operation', maxDisableClass('adults'))}
                onClick={() => selectPassengersHandler('adults', 'increase')}
              >
                <PlusIcon className="counter-icon" />
              </button>
            </div>
          </li>
          <li className="select-field-passenger-and-luggage__dropdown__list">
            <div>
              <ChildIcon />
              <p>Child (Below 17 years old)</p>
            </div>
            <div className="select-field-passenger-and-luggage__dropdown__list__operations">
              <button
                type="button"
                className={clsx('button-operation', selectedPassengers?.child == 0 ? 'disable' : '')}
                onClick={() => selectPassengersHandler('child', 'decrease')}
              >
                <MinusIcon className="counter-icon" />
              </button>
              <span>{selectedPassengers?.child || 0}</span>
              <button
                type="button"
                className={clsx('button-operation', maxDisableClass('child'))}
                onClick={() => selectPassengersHandler('child', 'increase')}
              >
                <PlusIcon className="counter-icon" />
              </button>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
};

export default SelectFieldPassengerAndLuggage;

import React, { useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as AirportIcon } from 'icons/airport-transfer-filled.svg';
import { ReactComponent as PointsIcon } from 'icons/points-icon.svg';
import { Button, InputField, TableWrapper } from 'components/Global';
import { indonesianDateFormat } from 'utils/helpers';
import { setCurrency } from 'utils/functionality';
import clsx from 'clsx';
import SelectFieldAirportTransferCar from 'components/Global/SelectFieldAirportTransferCar';
import TextAreaInput from 'components/Global/TextArea';
import { getPaymentMethodString } from 'utils/getPaymentMethod';
import { getVehicleById } from 'features/vehicle/action';
import Tabs, { TabPane } from 'components/Global/Tabs';
import { ReactComponent as ReuploadIcon } from 'icons/reupload-icon.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { downloadOrderDetail } from 'features/order-detail-pdf/actions';
import { getFileName } from 'utils/getFileName';
import { getLocationTimezone } from 'utils/getTimezone';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const RegularOrderDetail = ({
  data,
  setShowPriceDifference,
  selectedCar,
  setSelectedCar,
  allVehicle,
  newOrderData,
  oldOrderData,
  customer,
  imagePreviewHandler,
  selectedCountry,
  setShowBankTransferConfirmation,
  isPaymentValid,
  handleProcess,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast, setShowRejectOrderModal, setShowReviewIdentityModal } = useAppContext();

  const [activeTab, setActiveTab] = useState('Data Sekarang');
  const [vehicleInput, setVehicleInput] = useState('');

  const airportPackageVehicle = useSelector((state) => state.airportTransferOrder.selected);
  const vehicleById = useSelector((state) => state.vehicle.selected);

  const backHandler = () => {
    navigate(-1);
  };

  const handleExportToPdf = async () => {
    try {
      await dispatch(downloadOrderDetail(data?.transaction_key)).unwrap();
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal Export ke PDF' });
    }
  };

  const timezone = !data?.order_detail?.loc_time_id ? '' : getLocationTimezone(data?.order_detail?.loc_time_id);

  return (
    <div className="detail-order-airport">
      <div className="detail-order-airport__back-btn" onClick={backHandler}>
        <LeftArrow />
        <p>Kembali</p>
      </div>
      <TableWrapper
        icon={<AirportIcon fill="#009EF7" width="25px" height="25px" />}
        title="Airport Transfer"
        showExportButton
        onExportToPdf={handleExportToPdf}
        CustomFilterComponent={
          Object.prototype.hasOwnProperty.call(data, 'order_approval_history') ? (
            <div className="btn-export">
              <Button variant="primary" onClick={() => setShowPriceDifference(true)}>
                Detail Perbedaan
              </Button>
            </div>
          ) : null
        }
      >
        {(data?.order_status === 'PAID' || data?.order_status === 'COMPLETED' || data?.order_status === 'FINISHED') &&
          !data.order_approval_status && (
            <div className="detail-order-airport__selection">
              {/* SELECT CAR */}
              <SelectFieldAirportTransferCar
                label="Pilih Mobil"
                htmlFor="pilih-mobil"
                placeholder="Cari Mobil.."
                data={allVehicle}
                selectedCar={selectedCar}
                onSelectCar={setSelectedCar}
                value={vehicleInput}
                onChange={(e) => setVehicleInput(e.target.value)}
                onClick={(vehicleId, resetVehicle) => {
                  if (vehicleId === '') {
                    setVehicleInput(resetVehicle);
                    return;
                  }
                  dispatch(getVehicleById(vehicleId));
                  setVehicleInput(resetVehicle);
                }}
                disable={data.order_status !== 'PAID'}
              />
            </div>
          )}
        {data?.order_approval_status?.includes('UPDATE_ORDER') ? (
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
            <TabPane label="Data Sekarang">
              <div className="detail-order-airport__renter-detail">
                <div className="detail-order-airport__renter-detail-title">
                  <h1>Detail Penyewa</h1>
                </div>
                <div className="detail-order-airport__renter-detail-body">
                  <InputField label="Nama Lengkap" disabled value={newOrderData?.user_name || '-'} />
                  <InputField label="Email" disabled value={newOrderData?.email || '-'} />

                  <div className="detail-order-airport__renter-detail-body__phone-number">
                    <InputField
                      label="No Handphone"
                      disabled
                      value={
                        newOrderData?.is_admin_creation ? newOrderData?.phone_country_code : customer?.phone_code || '-'
                      }
                      icon={
                        <img
                          src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                          width={26}
                          height={16}
                          alt={selectedCountry?.name + ' nation flag'}
                        />
                      }
                    />
                    <InputField value={newOrderData?.phone_number || '-'} disabled />
                  </div>

                  <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                  <div className="detail-order-airport__renter-detail-body__phone-number">
                    <div className="detail-order-airport__renter-detail-body__phone-number-wrapper">
                      <InputField
                        label="Whatsapp"
                        disabled
                        value={
                          newOrderData?.is_admin_creation
                            ? newOrderData?.phone_country_code
                            : customer?.phone_code || '-'
                        }
                        icon={
                          <img
                            src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                            width={26}
                            height={16}
                            alt={selectedCountry?.name + ' nation flag'}
                          />
                        }
                      />
                      <div className="detail-order-airport__renter-detail-body__image-wrapper"></div>
                    </div>
                    <InputField disabled value={newOrderData?.wa_number || '-'} />
                  </div>
                </div>

                <div className="detail-order-airport__identity-detail-body">
                  <div className="detail-order-airport__identity-detail-body__preview-image">
                    <InputField
                      label="KTP"
                      disabled
                      value={
                        newOrderData?.is_admin_creation && getFileName(newOrderData?.order_detail?.identity?.ktp) !== ''
                          ? getFileName(newOrderData?.order_detail?.identity?.ktp)
                          : customer?.PersonalInfos?.ktp
                          ? getFileName(customer?.PersonalInfos?.ktp)
                          : 'Belum Upload KTP'
                      }
                      className="preview-image-input"
                    />
                    {(newOrderData?.is_admin_creation &&
                      getFileName(newOrderData?.order_detail?.identity?.ktp !== '')) ||
                    customer?.PersonalInfos?.ktp ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            newOrderData?.is_admin_creation
                              ? newOrderData?.order_detail?.identity?.ktp
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>

                  <div className="detail-order-airport__identity-detail-body__preview-image">
                    <InputField
                      label="SIM"
                      disabled
                      value={
                        newOrderData?.is_admin_creation && getFileName(newOrderData?.order_detail?.identity?.sim) !== ''
                          ? getFileName(newOrderData?.order_detail?.identity?.sim)
                          : customer?.PersonalInfos?.sim
                          ? getFileName(customer?.PersonalInfos?.sim)
                          : 'Belum Upload SIM'
                      }
                      className="preview-image-input"
                    />
                    {(newOrderData?.is_admin_creation &&
                      getFileName(newOrderData?.order_detail?.identity?.sim !== '')) ||
                    customer?.PersonalInfos?.sim ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            newOrderData?.is_admin_creation
                              ? newOrderData?.order_detail?.identity?.sim
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="detail-order-airport__rent-detail">
                <div className="detail-order-airport__rent-detail-title">
                  <h1>Detail Order</h1>
                </div>
                <div className="detail-order-airport__rent-detail-body">
                  <InputField
                    label="Lokasi Penjemputan"
                    disabled
                    value={newOrderData?.order_detail?.rental_delivery_location || '-'}
                  />
                  <InputField
                    label="Lokasi Pengantaran"
                    disabled
                    value={newOrderData?.order_detail?.rental_return_location || '-'}
                  />
                  <InputField
                    label="Detail Lokasi Penjemputan"
                    disabled
                    value={newOrderData?.order_detail?.rental_delivery_location_detail || '-'}
                  />
                  <InputField
                    label="Detail Lokasi Pengantaran"
                    disabled
                    value={newOrderData?.order_detail?.rental_return_location_detail || '-'}
                  />
                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Tanggal"
                      disabled
                      value={
                        newOrderData?.order_detail?.start_booking_date
                          ? indonesianDateFormat(newOrderData?.order_detail?.start_booking_date)
                          : '-'
                      }
                    />
                    <InputField
                      label="Jam"
                      disabled
                      value={(newOrderData?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                    />
                  </div>
                  <InputField
                    label="Nomor Penerbangan"
                    disabled
                    value={newOrderData?.order_detail?.flight_number || '-'}
                  />
                  <TextAreaInput
                    style={{
                      height:
                        newOrderData?.airport_package_vehicle_type?.split(', ').length >= 10
                          ? 96
                          : newOrderData?.airport_package_vehicle_type?.split(', ').length >= 5
                          ? 44 * 2
                          : 44,
                    }}
                    label="Jenis Mobil"
                    disabled
                    value={newOrderData?.airport_package_vehicle_type ?? '-'}
                    className="text-area-car-package"
                  />

                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Kapasitas Koper"
                      disabled
                      value={
                        newOrderData?.order_status === 'PENDING' || newOrderData?.order_status === 'CHECKOUT'
                          ? airportPackageVehicle?.max_suitecase
                          : newOrderData?.order_status === 'PAID' && !selectedCar
                          ? airportPackageVehicle?.max_suitecase
                          : vehicleById?.max_suitcase ?? '-'
                      }
                    />
                    <InputField label="Koper yang dibawa" disabled value={newOrderData?.order_detail?.baggage ?? 0} />
                  </div>
                </div>
              </div>

              <div className="detail-order-airport__price-detail">
                <div className="detail-order-airport__price-detail-title">
                  <h1>Rincian Biaya</h1>
                </div>

                <div className="detail-order-airport__price-detail-body">
                  <InputField
                    label="Biaya Sewa Mobil"
                    disabled
                    value={setCurrency(newOrderData?.booking_price) || '-'}
                  />
                  <InputField
                    label="Biaya Pengantaran"
                    disabled
                    value={setCurrency(newOrderData?.rental_delivery_fee) || '-'}
                  />
                  {newOrderData?.outside_operational_charge > 0 && (
                    <InputField
                      label="Outside Operational Hour Charge"
                      disabled
                      value={setCurrency(newOrderData?.outside_operational_charge) || '-'}
                    />
                  )}
                  <InputField
                    label="Pembayaran Dengan Point"
                    disabled
                    value={newOrderData?.point}
                    icon={<PointsIcon />}
                    iconPosition="start"
                  />
                  <InputField
                    label="Charge Payment Gateway Platform"
                    disabled
                    value={setCurrency(newOrderData?.disbursement?.total_amount_external_payment_fee) || '-'}
                  />
                  <InputField
                    label="Customer Service Platform"
                    disabled
                    value={setCurrency(newOrderData?.disbursement?.total_amount_customer_service_fee) || '-'}
                  />
                  <InputField
                    label="Total Keseluruhan"
                    disabled
                    value={setCurrency(newOrderData?.total_payment) || '-'}
                  />
                </div>
              </div>

              <div className="detail-order__voucher-detail">
                <div className="detail-order__voucher-detail-title">
                  <h1>Voucher</h1>
                </div>
                <div className="detail-order__voucher-detail-body">
                  <InputField label="Nama Voucher" disabled value={newOrderData?.vouchers?.[0].name || '-'} />
                  <InputField label="Kode Voucher" disabled value={newOrderData?.vouchers?.[0].code || '-'} />
                  <InputField
                    label="Harga Potongan"
                    disabled
                    value={setCurrency(newOrderData?.vouchers?.[0].value) || '-'}
                  />
                </div>
              </div>

              <div className="detail-order-airport__payment-detail">
                <div className="detail-order-airport__payment-detail-title">
                  <h1>Pembayaran</h1>
                </div>
                <div className="detail-order-airport__payment-detail-body">
                  <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                  <div className={clsx('detail-order-airport__payment-detail-body__payment-method confirmation')}>
                    <InputField
                      label="Metode Pembayaran"
                      disabled
                      // value={data?.disbursement?.payment?.method ? data?.disbursement.payment.method : '-'}
                      value={getPaymentMethodString(data?.disbursement_recalculate)}
                    />
                    {data.order_status === 'CHECKOUT' &&
                    data?.order_approval_status !== 'WAITING_APPROVAL_DELETE_ORDER' ? (
                      <Button
                        height={44}
                        onClick={() => setShowBankTransferConfirmation(true)}
                        disabled={isPaymentValid}
                      >
                        Konfirmasi
                      </Button>
                    ) : null}
                  </div>

                  {data.order_status !== 'CHECKOUT' &&
                  data.order_status !== 'RECONFIRMATION' &&
                  Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                    <>
                      <InputField
                        label="Nama Rekening"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_name
                            : data?.disbursement?.sender_name || '-'
                        }
                      />

                      <InputField
                        label="Nama Bank"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                            : data?.disbursement?.sender_bank_name || '-'
                        }
                      />

                      <div className="detail-order__payment-detail-body__preview-image">
                        <InputField
                          label="Foto Bukti Transfer"
                          disabled
                          value={getFileName(
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                              : data?.disbursement?.disbursement_confirmation_image,
                          )}
                          className="preview-image-input"
                        />

                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                : data?.disbursement?.disbursement_confirmation_image,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      </div>
                      <div />
                    </>
                  ) : null}
                </div>
              </div>

              <div className="detail-order-airport__transaction-buttons">
                <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                  Kembali
                </Button>
              </div>
            </TabPane>
            <TabPane label="Data Sebelum">
              <div className="detail-order-airport__renter-detail">
                <div className="detail-order-airport__renter-detail-title">
                  <h1>Detail Penyewa</h1>
                </div>
                <div className="detail-order-airport__renter-detail-body">
                  <InputField label="Nama Lengkap" disabled value={oldOrderData?.user_name || '-'} />
                  <InputField label="Email" disabled value={oldOrderData?.email || '-'} />

                  <div className="detail-order-airport__renter-detail-body__phone-number">
                    <InputField
                      label="No Handphone"
                      disabled
                      value={
                        oldOrderData?.is_admin_creation ? oldOrderData?.phone_country_code : customer?.phone_code || '-'
                      }
                      icon={
                        <img
                          src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                          width={26}
                          height={16}
                          alt={selectedCountry?.name + ' nation flag'}
                        />
                      }
                    />
                    <InputField value={oldOrderData?.phone_number || '-'} disabled />
                  </div>

                  <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                  <div className="detail-order-airport__renter-detail-body__phone-number">
                    <div className="detail-order-airport__renter-detail-body__phone-number-wrapper">
                      <InputField
                        label="Whatsapp"
                        disabled
                        value={
                          oldOrderData?.is_admin_creation
                            ? oldOrderData?.phone_country_code
                            : customer?.phone_code || '-'
                        }
                        icon={
                          <img
                            src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                            width={26}
                            height={16}
                            alt={selectedCountry?.name + ' nation flag'}
                          />
                        }
                      />
                      <div className="detail-order-airport__renter-detail-body__image-wrapper"></div>
                    </div>
                    <InputField disabled value={oldOrderData?.wa_number || '-'} />
                  </div>
                </div>

                <div className="detail-order-airport__identity-detail-body">
                  <div className="detail-order-airport__identity-detail-body__preview-image">
                    <InputField
                      label="KTP"
                      disabled
                      value={
                        oldOrderData?.is_admin_creation && getFileName(oldOrderData?.order_detail?.identity?.ktp) !== ''
                          ? getFileName(oldOrderData?.order_detail?.identity?.ktp)
                          : customer?.PersonalInfos?.ktp
                          ? getFileName(customer?.PersonalInfos?.ktp)
                          : 'Belum Upload KTP'
                      }
                      className="preview-image-input"
                    />
                    {(oldOrderData?.is_admin_creation &&
                      getFileName(oldOrderData?.order_detail?.identity?.ktp !== '')) ||
                    customer?.PersonalInfos?.ktp ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            oldOrderData?.is_admin_creation
                              ? oldOrderData?.order_detail?.identity?.ktp
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>

                  <div className="detail-order-airport__identity-detail-body__preview-image">
                    <InputField
                      label="SIM"
                      disabled
                      value={
                        oldOrderData?.is_admin_creation && getFileName(oldOrderData?.order_detail?.identity?.sim) !== ''
                          ? getFileName(oldOrderData?.order_detail?.identity?.sim)
                          : customer?.PersonalInfos?.sim
                          ? getFileName(customer?.PersonalInfos?.sim)
                          : 'Belum Upload SIM'
                      }
                      className="preview-image-input"
                    />
                    {(oldOrderData?.is_admin_creation &&
                      getFileName(oldOrderData?.order_detail?.identity?.sim !== '')) ||
                    customer?.PersonalInfos?.sim ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            oldOrderData?.is_admin_creation
                              ? oldOrderData?.order_detail?.identity?.sim
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="detail-order-airport__rent-detail">
                <div className="detail-order-airport__rent-detail-title">
                  <h1>Detail Order</h1>
                </div>
                <div className="detail-order-airport__rent-detail-body">
                  <InputField
                    label="Lokasi Penjemputan"
                    disabled
                    value={oldOrderData?.order_detail?.rental_delivery_location || '-'}
                  />
                  <InputField
                    label="Lokasi Pengantaran"
                    disabled
                    value={oldOrderData?.order_detail?.rental_return_location || '-'}
                  />
                  <InputField
                    label="Detail Lokasi Penjemputan"
                    disabled
                    value={oldOrderData?.order_detail?.rental_delivery_location_detail || '-'}
                  />
                  <InputField
                    label="Detail Lokasi Pengantaran"
                    disabled
                    value={oldOrderData?.order_detail?.rental_return_location_detail || '-'}
                  />
                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Tanggal"
                      disabled
                      value={
                        oldOrderData?.order_detail?.start_booking_date
                          ? indonesianDateFormat(oldOrderData?.order_detail?.start_booking_date)
                          : '-'
                      }
                    />
                    <InputField
                      label="Jam"
                      disabled
                      value={(oldOrderData?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                    />
                  </div>
                  <InputField
                    label="Nomor Penerbangan"
                    disabled
                    value={oldOrderData?.order_detail?.flight_number || '-'}
                  />
                  <TextAreaInput
                    style={{
                      height:
                        oldOrderData?.airport_package_vehicle_type?.split(', ').length >= 10
                          ? 96
                          : oldOrderData?.airport_package_vehicle_type?.split(', ').length >= 5
                          ? 44 * 2
                          : 44,
                    }}
                    label="Jenis Mobil"
                    disabled
                    value={oldOrderData?.airport_package_vehicle_type ?? '-'}
                    className="text-area-car-package"
                  />

                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Kapasitas Koper"
                      disabled
                      value={
                        oldOrderData?.order_status === 'PENDING' || oldOrderData?.order_status === 'CHECKOUT'
                          ? airportPackageVehicle?.max_suitecase
                          : oldOrderData?.order_status === 'PAID' && !selectedCar
                          ? airportPackageVehicle?.max_suitecase
                          : vehicleById?.max_suitcase ?? '-'
                      }
                    />
                    <InputField label="Koper yang dibawa" disabled value={oldOrderData?.order_detail?.baggage ?? 0} />
                  </div>
                </div>
              </div>

              <div className="detail-order-airport__price-detail">
                <div className="detail-order-airport__price-detail-title">
                  <h1>Rincian Biaya</h1>
                </div>

                <div className="detail-order-airport__price-detail-body">
                  <InputField
                    label="Biaya Sewa Mobil"
                    disabled
                    value={setCurrency(oldOrderData?.booking_price) || '-'}
                  />
                  <InputField
                    label="Biaya Pengantaran"
                    disabled
                    value={setCurrency(oldOrderData?.rental_delivery_fee) || '-'}
                  />
                  {oldOrderData?.outside_operational_charge > 0 && (
                    <InputField
                      label="Outside Operational Hour Charge"
                      disabled
                      value={setCurrency(oldOrderData?.outside_operational_charge) || '-'}
                    />
                  )}
                  <InputField
                    label="Pembayaran Dengan Point"
                    disabled
                    value={oldOrderData?.point}
                    icon={<PointsIcon />}
                    iconPosition="start"
                  />
                  <InputField
                    label="Charge Payment Gateway Platform"
                    disabled
                    value={setCurrency(oldOrderData?.disbursement?.total_amount_external_payment_fee) || '-'}
                  />
                  <InputField
                    label="Customer Service Platform"
                    disabled
                    value={setCurrency(oldOrderData?.disbursement?.total_amount_customer_service_fee) || '-'}
                  />
                  <InputField
                    label="Total Keseluruhan"
                    disabled
                    value={setCurrency(oldOrderData?.total_payment) || '-'}
                  />
                </div>
              </div>

              <div className="detail-order__voucher-detail">
                <div className="detail-order__voucher-detail-title">
                  <h1>Voucher</h1>
                </div>
                <div className="detail-order__voucher-detail-body">
                  <InputField label="Nama Voucher" disabled value={oldOrderData?.vouchers?.[0].name || '-'} />
                  <InputField label="Kode Voucher" disabled value={oldOrderData?.vouchers?.[0].code || '-'} />
                  <InputField
                    label="Harga Potongan"
                    disabled
                    value={setCurrency(oldOrderData?.vouchers?.[0].value) || '-'}
                  />
                </div>
              </div>

              <div className="detail-order-airport__payment-detail">
                <div className="detail-order-airport__payment-detail-title">
                  <h1>Pembayaran</h1>
                </div>
                <div className="detail-order-airport__payment-detail-body">
                  <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                  <div
                    className={clsx(
                      'detail-order-airport__payment-detail-body__payment-method',
                      data.order_status === 'CHECKOUT' ? 'confirmation' : 'not-confirmation',
                    )}
                  >
                    <InputField
                      label="Metode Pembayaran"
                      disabled
                      // value={data?.disbursement?.payment?.method ? data?.disbursement.payment.method : '-'}
                      value={getPaymentMethodString(data.disbursement)}
                    />
                  </div>

                  {data.order_status !== 'CHECKOUT' &&
                  data.order_status !== 'RECONFIRMATION' &&
                  Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                    <>
                      <InputField
                        label="Nama Rekening"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_name
                            : data?.disbursement?.sender_name || '-'
                        }
                      />

                      <InputField
                        label="Nama Bank"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                            : data?.disbursement?.sender_bank_name || '-'
                        }
                      />

                      <div className="detail-order__payment-detail-body__preview-image">
                        <InputField
                          label="Foto Bukti Transfer"
                          disabled
                          value={getFileName(
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                              : data?.disbursement?.disbursement_confirmation_image,
                          )}
                          className="preview-image-input"
                        />

                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                : data?.disbursement?.disbursement_confirmation_image,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      </div>
                      <div />
                    </>
                  ) : null}
                </div>
              </div>

              <div className="detail-order-airport__transaction-buttons">
                <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                  Kembali
                </Button>
              </div>
            </TabPane>
          </Tabs>
        ) : (
          <>
            <div className="detail-order-airport__renter-detail">
              <div className="detail-order-airport__renter-detail-title">
                <h1>Detail Penyewa</h1>
              </div>
              <div className="detail-order-airport__renter-detail-body">
                <InputField label="Nama Lengkap" disabled value={data?.user_name || '-'} />
                <InputField label="Email" disabled value={data?.email || '-'} />

                <div className="detail-order-airport__renter-detail-body__phone-number">
                  <InputField
                    label="No Handphone"
                    disabled
                    value={data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code || '-'}
                    icon={
                      <img
                        src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                        width={26}
                        height={16}
                        alt={selectedCountry?.name + ' nation flag'}
                      />
                    }
                  />
                  <InputField value={data?.phone_number || '-'} disabled />
                </div>

                <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                <div className="detail-order-airport__renter-detail-body__phone-number">
                  <div className="detail-order-airport__renter-detail-body__phone-number-wrapper">
                    <InputField
                      label="Whatsapp"
                      disabled
                      value={data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code || '-'}
                      icon={
                        <img
                          src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                          width={26}
                          height={16}
                          alt={selectedCountry?.name + ' nation flag'}
                        />
                      }
                    />
                    <div className="detail-order-airport__renter-detail-body__image-wrapper"></div>
                  </div>
                  <InputField disabled value={data?.wa_number || '-'} />
                </div>
              </div>

              <div className="detail-order-airport__identity-detail-body">
                <div className="detail-order-airport__identity-detail-body__preview-image">
                  <InputField
                    label="KTP"
                    disabled
                    value={
                      data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== ''
                        ? getFileName(data?.order_detail?.identity?.ktp)
                        : customer?.PersonalInfos?.ktp
                        ? getFileName(customer?.PersonalInfos?.ktp)
                        : 'Belum Upload KTP'
                    }
                    className="preview-image-input"
                  />
                  {data?.order_status !== 'PAID' &&
                  data?.order_status !== 'REVIEWING_IDENTITY' &&
                  ((data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== '') ||
                    customer?.PersonalInfos?.ktp) ? (
                    <Button
                      bgColor="#D9D9D9"
                      textColor="#000000"
                      className="preview-image-btn"
                      onClick={() =>
                        imagePreviewHandler(
                          data?.is_admin_creation
                            ? data?.order_detail?.identity?.ktp
                            : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                        )
                      }
                    >
                      Lihat
                    </Button>
                  ) : null}
                  {(data?.order_status === 'PAID' || data?.order_status === 'REVIEWING_IDENTITY') && (
                    <Button
                      type="button"
                      className="validation-identity-btn"
                      onClick={() => setShowReviewIdentityModal({ type: 'ktp', show: true })}
                    >
                      Tinjau
                    </Button>
                  )}
                  {(data?.order_status === 'PAID' || data?.order_status === 'REVIEWING_IDENTITY') &&
                    customer?.PersonalInfos?.need_review_ktp && (
                      <div className="reupload-icon">
                        <ReuploadIcon />
                        <p>Upload ulang KTP</p>
                      </div>
                    )}
                </div>

                <div className="detail-order-airport__identity-detail-body__preview-image">
                  <InputField
                    label="SIM"
                    disabled
                    value={
                      data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== ''
                        ? getFileName(data?.order_detail?.identity?.sim)
                        : customer?.PersonalInfos?.sim
                        ? getFileName(customer?.PersonalInfos?.sim)
                        : 'Belum Upload SIM'
                    }
                    className="preview-image-input"
                  />
                  {data?.order_status !== 'PAID' &&
                  data?.order_status !== 'REVIEWING_IDENTITY' &&
                  ((data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== '') ||
                    customer?.PersonalInfos?.sim) ? (
                    <Button
                      bgColor="#D9D9D9"
                      textColor="#000000"
                      className="preview-image-btn"
                      onClick={() =>
                        imagePreviewHandler(
                          data?.is_admin_creation
                            ? data?.order_detail?.identity?.sim
                            : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                        )
                      }
                    >
                      Lihat
                    </Button>
                  ) : null}
                  {(data?.order_status === 'PAID' || data?.order_status === 'REVIEWING_IDENTITY') && (
                    <Button
                      type="button"
                      className="validation-identity-btn"
                      onClick={() => setShowReviewIdentityModal({ type: 'sim', show: true })}
                    >
                      Tinjau
                    </Button>
                  )}
                  {(data?.order_status === 'PAID' || data?.order_status === 'REVIEWING_IDENTITY') &&
                    customer?.PersonalInfos?.need_review_sim && (
                      <div className="reupload-icon">
                        <ReuploadIcon />
                        <p>Upload ulang SIM</p>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="detail-order-airport__rent-detail">
              <div className="detail-order-airport__rent-detail-title">
                <h1>Detail Order</h1>
              </div>
              <div className="detail-order-airport__rent-detail-body">
                <InputField
                  label="Lokasi Penjemputan"
                  disabled
                  value={data?.order_detail?.rental_delivery_location || '-'}
                />
                <InputField
                  label="Lokasi Pengantaran"
                  disabled
                  value={data?.order_detail?.rental_return_location || '-'}
                />
                <InputField
                  label="Detail Lokasi Penjemputan"
                  disabled
                  value={data?.order_detail?.rental_delivery_location_detail || '-'}
                />
                <InputField
                  label="Detail Lokasi Pengantaran"
                  disabled
                  value={data?.order_detail?.rental_return_location_detail || '-'}
                />
                <div className="detail-order__rent-detail-body__rent-date">
                  <InputField
                    label="Tanggal"
                    disabled
                    value={
                      data?.order_detail?.start_booking_date
                        ? indonesianDateFormat(data?.order_detail?.start_booking_date)
                        : '-'
                    }
                  />
                  <InputField
                    label="Jam"
                    disabled
                    value={(data?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                  />
                </div>
                <InputField label="Nomor Penerbangan" disabled value={data?.order_detail?.flight_number || '-'} />
                <TextAreaInput
                  style={{
                    height:
                      data?.airport_package_vehicle_type?.split(', ').length >= 10
                        ? 96
                        : data?.airport_package_vehicle_type?.split(', ').length >= 5
                        ? 44 * 2
                        : 44,
                  }}
                  label="Jenis Mobil"
                  disabled
                  value={data?.airport_package_vehicle_type ?? '-'}
                  className="text-area-car-package"
                />

                <div className="detail-order__rent-detail-body__rent-date">
                  <InputField
                    label="Kapasitas Koper"
                    disabled
                    value={
                      data?.order_status === 'PENDING' || data?.order_status === 'CHECKOUT'
                        ? airportPackageVehicle?.max_suitecase
                        : data?.order_status === 'PAID' && !selectedCar
                        ? airportPackageVehicle?.max_suitecase
                        : vehicleById?.max_suitcase ?? '-'
                    }
                  />
                  <InputField label="Koper yang dibawa" disabled value={data?.order_detail?.baggage ?? 0} />
                </div>
              </div>
            </div>

            <div className="detail-order-airport__price-detail">
              <div className="detail-order-airport__price-detail-title">
                <h1>Rincian Biaya</h1>
              </div>

              <div className="detail-order-airport__price-detail-body">
                <InputField label="Biaya Sewa Mobil" disabled value={setCurrency(data?.booking_price) || '-'} />
                <InputField label="Biaya Pengantaran" disabled value={setCurrency(data?.rental_delivery_fee) || '-'} />
                {data?.outside_operational_charge > 0 && (
                  <InputField
                    label="Outside Operational Hour Charge"
                    disabled
                    value={setCurrency(data?.outside_operational_charge) || '-'}
                  />
                )}
                <InputField
                  label="Pembayaran Dengan Point"
                  disabled
                  value={data?.point}
                  icon={<PointsIcon />}
                  iconPosition="start"
                />
                <InputField
                  label="Charge Payment Gateway Platform"
                  disabled
                  value={setCurrency(data?.disbursement?.total_amount_external_payment_fee) || '-'}
                />
                <InputField
                  label="Customer Service Platform"
                  disabled
                  value={setCurrency(data?.disbursement?.total_amount_customer_service_fee) || '-'}
                />
                <InputField
                  label="Total Harga Bersih"
                  disabled
                  value={setCurrency(data?.disbursement?.total_net_amount) || '-'}
                />
                <InputField
                  label="Total Bruto"
                  disabled
                  value={setCurrency(data?.disbursement?.total_amount_with_customer_service_fee) || '-'}
                />
                <InputField label="Total Keseluruhan" disabled value={setCurrency(data?.total_payment) || '-'} />
                {Object.prototype.hasOwnProperty.call(data, 'order_approval_history') ? (
                  <InputField
                    label="Harga Selisih"
                    disabled
                    value={setCurrency(data?.total_diff_price_with_approval) || '-'}
                  />
                ) : null}
              </div>
            </div>

            <div className="detail-order__voucher-detail">
              <div className="detail-order__voucher-detail-title">
                <h1>Voucher</h1>
              </div>
              <div className="detail-order__voucher-detail-body">
                <InputField label="Nama Voucher" disabled value={data?.vouchers?.[0].name || '-'} />
                <InputField label="Kode Voucher" disabled value={data?.vouchers?.[0].code || '-'} />
                <InputField label="Harga Potongan" disabled value={setCurrency(data?.vouchers?.[0].value) || '-'} />
              </div>
            </div>

            <div className="detail-order-airport__payment-detail">
              <div className="detail-order-airport__payment-detail-title">
                <h1>Pembayaran</h1>
              </div>
              <div className="detail-order-airport__payment-detail-body">
                <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                <div
                  className={clsx(
                    'detail-order-airport__payment-detail-body__payment-method',
                    data.order_status === 'CHECKOUT' ? 'confirmation' : 'not-confirmation',
                  )}
                >
                  <InputField
                    label="Metode Pembayaran"
                    disabled
                    // value={data?.disbursement?.payment?.method ? data?.disbursement.payment.method : '-'}
                    value={getPaymentMethodString(data.disbursement)}
                  />
                  {data.order_status === 'CHECKOUT' &&
                  data?.order_approval_status !== 'WAITING_APPROVAL_DELETE_ORDER' ? (
                    <Button height={44} onClick={() => setShowBankTransferConfirmation(true)} disabled={isPaymentValid}>
                      Konfirmasi
                    </Button>
                  ) : null}
                </div>

                {data.order_status !== 'CHECKOUT' &&
                data.order_status !== 'RECONFIRMATION' &&
                Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                  <>
                    <InputField
                      label="Nama Rekening"
                      disabled
                      value={
                        Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                          ? [...data.disbursement_reconfirmation].pop()?.sender_name
                          : data?.disbursement?.sender_name || '-'
                      }
                    />

                    <InputField
                      label="Nama Bank"
                      disabled
                      value={
                        Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                          ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                          : data?.disbursement?.sender_bank_name || '-'
                      }
                    />

                    <div className="detail-order__payment-detail-body__preview-image">
                      <InputField
                        label="Foto Bukti Transfer"
                        disabled
                        value={getFileName(
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                            : data?.disbursement?.disbursement_confirmation_image,
                        )}
                        className="preview-image-input"
                      />

                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                              : data?.disbursement?.disbursement_confirmation_image,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    </div>
                    <div />
                  </>
                ) : null}
              </div>
            </div>

            <div className="detail-order-airport__transaction-buttons">
              <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                Kembali
              </Button>
              {(data.order_status === 'CHECKOUT' || data.order_status === 'REVIEWING_IDENTITY') &&
              !data.order_approval_status ? (
                <Button
                  size="sm"
                  className="button"
                  width={208}
                  variant="danger"
                  onClick={() => setShowRejectOrderModal(true)}
                >
                  Tolak Pesanan
                </Button>
              ) : null}
              {(data.order_status === 'PAID' ||
                (data.order_status === 'REVIEWING_IDENTITY' &&
                  !customer?.PersonalInfos?.need_review_sim &&
                  !customer?.PersonalInfos?.need_review_ktp)) &&
              !data.order_approval_status ? (
                <Button variant="success" className="button" width={208} size="sm" onClick={handleProcess}>
                  Proses
                </Button>
              ) : null}
            </div>
          </>
        )}
      </TableWrapper>
    </div>
  );
};

export default RegularOrderDetail;

import { useState } from 'react';
import { ReactComponent as UserIcon } from 'icons/user-filled-icon.svg';
import { format, isToday, isYesterday } from 'date-fns';
import clsx from 'clsx';

const Sidebar = ({
  inbox,
  onInboxSelect,
  chatView,
  chatType,
  onSelectView,
  onSelectType,
  chatHistory,
  unreadCount,
  selectedInbox,
}) => {
  const formatChatTime = (createdAt) => {
    const date = new Date(createdAt);

    if (isToday(date)) {
      return format(date, 'HH:mm');
    } else if (isYesterday(date)) {
      return 'Yesterday';
    } else if (date.getFullYear() === new Date().getFullYear()) {
      return format(date, 'MMM d');
    } else {
      return format(date, 'dd/MM/yyyy');
    }
  };

  const [searchQuery, setSearchQuery] = useState('');

  const filteredInbox = inbox.filter((item) => item.room.room_type === chatType) ?? [];

  const filteredItems = searchQuery
    ? filteredInbox.filter((item) => item.room?.name.toLowerCase().includes(searchQuery.toLowerCase()))
    : filteredInbox;

  const rooms = [
    { label: 'Order', type: 'cs-order' },
    { label: 'Driver', type: 'cs-driver' },
    { label: 'User', type: 'cs-customer' },
  ];

  const getCount = (type) => unreadCount.find((item) => item.room_type === type)?.count || 0;

  return (
    <div className="chat__sidebar">
      <div className="chat__sidebar-header">
        <div className="tabs">
          {rooms.map((room) => (
            <button
              key={room.type}
              className={`tab-button-type ${chatType === room.type ? 'tab-button-type__active' : ''}`}
              id={room.type}
              onClick={onSelectType}
            >
              {room.label}
              <span className="tabs-badge">{getCount(room.type)}</span>
            </button>
          ))}
        </div>

        <div className="tabs">
          <button
            className={`tab-button ${chatView === 'online' ? 'tab-button__active' : ''}`}
            id="online"
            onClick={onSelectView}
          >
            Online
          </button>
          <button
            className={`tab-button ${chatView === 'history' ? 'tab-button__active' : ''}`}
            id="history"
            onClick={onSelectView}
          >
            History
          </button>
        </div>
      </div>

      <div className="chat__sidebar-wrapper">
        <div className="search-input">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search..."
            className="search-input__field"
          />
        </div>

        <div className="tabs-content">
          {/* ONLINE */}
          <div className={`tab-page ${chatView === 'online' ? 'tab-page__active' : ''}`}>
            <div className="chat__sidebar-chat-list">
              {filteredItems.length ? (
                filteredItems?.map((item) => (
                  <div
                    onClick={() => onInboxSelect(item)}
                    key={item.message.user_id}
                    className={clsx(
                      'chat__sidebar-chat-item',
                      item.room.id === selectedInbox?.room.id && 'chat__sidebar-chat-item__selected',
                      item.message.is_read === false && 'chat__sidebar-chat-item__unread',
                    )}
                  >
                    <div className="chat__sidebar-user-icon">
                      <UserIcon />
                    </div>
                    <div className="chat__sidebar-chat-info">
                      <div className="chat__sidebar-info-item">
                        <span className="chat__sidebar-info-item__chat-id">{item.room.name}</span>
                        <span className="chat__sidebar-info-item__chat-time">
                          {formatChatTime(item.message.created_at)}
                        </span>
                      </div>

                      {item.message.message ? (
                        <div className="chat__sidebar-chat-history">
                          <p>{item.message.message}</p>
                        </div>
                      ) : null}

                      {item.message.file ? (
                        <div className="chat__sidebar-chat-image">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            fill="none"
                            viewBox="0 0 15 15"
                          >
                            <path
                              fill="currentColor"
                              fillRule="evenodd"
                              d="M2.5 1h10A1.5 1.5 0 0 1 14 2.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 1 12.5v-10A1.5 1.5 0 0 1 2.5 1m0 1a.5.5 0 0 0-.5.5v5.864l1.682-1.682a.45.45 0 0 1 .647.01l3.545 3.798 2.808-2.808a.45.45 0 0 1 .636 0L13 9.364V2.5a.5.5 0 0 0-.5-.5zM2 12.5V9.636l1.989-1.988 3.542 3.794L8.941 13H2.5a.5.5 0 0 1-.5-.5m10.5.5h-2.345l-1.672-1.847L11 8.636l2 2V12.5a.5.5 0 0 1-.5.5M6.65 5.5a.85.85 0 1 1 1.7 0 .85.85 0 0 1-1.7 0m.85-1.75a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <p>Image</p>
                        </div>
                      ) : null}

                      <div className="chat__sidebar-chat-badge chat__sidebar-chat-badge__open">Open</div>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyInbox />
              )}
            </div>
          </div>

          {/* HISTORY */}
          <div className={`tab-page ${chatView === 'history' ? 'tab-page__active' : ''}`}>
            <div className="chat__sidebar-chat-list">
              {chatHistory ? (
                chatHistory?.map((item) => (
                  <div
                    onClick={() => onInboxSelect(item)}
                    key={item.message.user_id}
                    className="chat__sidebar-chat-item chat__sidebar-chat-item__selected"
                  >
                    <div className="chat__sidebar-user-icon">
                      <UserIcon />
                    </div>
                    <div className="chat__sidebar-chat-info">
                      <div className="chat__sidebar-info-item">
                        <span className="chat__sidebar-info-item__chat-id">{item.room.name}</span>
                        <span className="chat__sidebar-info-item__chat-time">
                          {formatChatTime(item.message.created_at)}
                        </span>
                      </div>

                      <div className="chat__sidebar-chat-history">
                        <p>{item.message.message}</p>
                      </div>

                      <div className="chat__sidebar-chat-badge chat__sidebar-chat-badge__close">Close</div>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyInbox />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

const EmptyInbox = () => {
  return (
    <div className="empty-chat">
      <div className="empty-chat__wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="114" height="93" fill="none" viewBox="0 0 114 93">
          <path
            fill="#D5D5D5"
            d="M10.364 46.5C4.664 46.5 0 41.85 0 36.167V10.333C0 4.65 4.664 0 10.364 0h41.454c5.7 0 10.364 4.65 10.364 10.333v25.834c0 5.683-4.664 10.333-10.364 10.333H41.455V62L25.909 46.5zm93.272 31c5.7 0 10.364-4.65 10.364-10.333V41.333C114 35.65 109.336 31 103.636 31h-31.09v5.167c0 11.366-9.328 20.666-20.728 20.666v10.334c0 5.683 4.664 10.333 10.364 10.333h10.364V93L88.09 77.5z"
          ></path>
        </svg>
        <p>Belum Ada Aktivitas</p>
      </div>
    </div>
  );
};

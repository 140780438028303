import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { Button, InputField, TableWrapper } from 'components/Global';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch } from 'react-redux';
import { updateApprovalStatus } from 'features/approvals/actions';
import { useNavigate } from 'react-router-dom';
import { getFileName } from 'utils/getFileName';

const BankTransferConfirmation = ({
  setShowBankTransferConfirmation,
  handleProcess,
  isPaymentValid,
  orderData: data,
  imagePreviewHandler,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setShowCancelPaymentMessage, setCancelPaymentMessageValue, setShowConfirmation, showToast } = useAppContext();
  const [isReconfirmation, setIsReconfirmation] = useState(false);
  const [latestReconfirmationData, setLatestReconfirmationData] = useState([]);
  const [isRecalculate, setIsRecalculate] = useState(false);
  const [recalculateData, setRecalculateData] = useState({});

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')) return;
    const reconfirmationReconfirmationDatas = [...data.disbursement_reconfirmation];
    setLatestReconfirmationData(reconfirmationReconfirmationDatas.pop());
  }, [data]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')) {
      setIsReconfirmation(true);
    } else {
      setIsReconfirmation(false);
    }
  }, [data]);

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(data, 'approval')) return;
    if (data.approval === null) return;
    if (!Object.prototype.hasOwnProperty.call(data.approval.data, 'disbursement')) return;
    setRecalculateData(data.approval.data.disbursement);
  }, [data]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(data, 'disbursement_recalculate')) {
      setIsRecalculate(true);
    } else {
      setIsRecalculate(false);
    }
  }, [data]);

  const rejectHandler = () => {
    if (data?.order_approval_status?.includes('UPDATE_ORDER')) {
      setShowConfirmation({
        message: 'Apakah anda yakin ingin menolak pembayaran ini?',
        show: true,
        onClick: async () => {
          try {
            await dispatch(updateApprovalStatus({ status: 'rejected', id: data?.approval?.id, skip: true })).unwrap();

            showToast({ type: 'success', message: 'Pembayaran Berhasil Ditolak' });
          } catch (err) {
            showToast({ type: 'error', message: 'Pembayaran Gagal Ditolak' });
          } finally {
            navigate(-1);
          }
        },
      });
    } else {
      setShowCancelPaymentMessage(true);
    }
  };

  // RESET CANCEL PAYMENT MESSAGE
  useEffect(() => {
    setCancelPaymentMessageValue('');
  }, []);

  return (
    <div className="detail-order">
      <div className="detail-order__back-btn" onClick={() => setShowBankTransferConfirmation(false)}>
        <LeftArrow />
        <p>Kembali</p>
      </div>
      <TableWrapper icon={<CarIcon fill="#009EF7" width="25px" height="25px" />} title="Konfirmasi Bank Transfer">
        <div className="detail-order__renter-detail">
          <div className="detail-order__renter-detail-title">
            <h1>Detail Penyewa</h1>
          </div>
          <div className="detail-order__renter-detail-body">
            <InputField
              label="Nama Pengirim"
              disabled
              value={
                isReconfirmation
                  ? latestReconfirmationData.sender_name
                  : isRecalculate
                  ? recalculateData.sender_name
                  : data.disbursement.sender_name
              }
            />
            <InputField
              label="Nama Bank"
              disabled
              value={
                isReconfirmation
                  ? latestReconfirmationData.sender_bank_name
                  : isRecalculate
                  ? recalculateData.sender_bank_name
                  : data.disbursement.sender_bank_name
              }
            />

            <div className="detail-order__payment-detail-body__preview-image">
              <InputField
                label="Foto Bukti Transfer"
                disabled
                value={
                  isRecalculate
                    ? recalculateData.disbursement_confirmation_image?.substring(0, 100)
                    : getFileName(
                        isReconfirmation
                          ? latestReconfirmationData.reconfirmation_image
                          : data.disbursement.disbursement_confirmation_image,
                      )
                }
                className="preview-image-input"
              />

              <Button
                bgColor="#D9D9D9"
                textColor="#000000"
                className="preview-image-btn"
                onClick={() =>
                  imagePreviewHandler(
                    isReconfirmation
                      ? latestReconfirmationData.reconfirmation_image
                      : isRecalculate
                      ? recalculateData.disbursement_confirmation_image
                      : data.disbursement.disbursement_confirmation_image,
                  )
                }
              >
                Lihat
              </Button>
            </div>
          </div>
        </div>

        <div className="detail-order__transaction-buttons">
          <Button
            variant="outline"
            className="button"
            width={134}
            size="sm"
            onClick={() => setShowBankTransferConfirmation(false)}
          >
            Kembali
          </Button>
          {data.order_status === 'CHECKOUT' ? (
            <>
              <Button variant="danger" className="button" width={179} size="sm" onClick={rejectHandler}>
                Tolak Pembayaran
              </Button>
              <Button
                variant="success"
                className="button"
                width={192}
                size="sm"
                onClick={handleProcess}
                disabled={isPaymentValid}
              >
                Konfirmasi Pembayaran
              </Button>
            </>
          ) : null}
        </div>
      </TableWrapper>
    </div>
  );
};

export default BankTransferConfirmation;

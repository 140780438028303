import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { format } from 'date-fns';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import { InputField, SelectFieldAirportPackage } from 'components/Global';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import SelectFieldTime from 'components/Global/SelectFieldTime';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import { ReactComponent as SwitchIcon } from 'icons/switch-icon.svg';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSummaryAirportTransferOrder } from 'features/orders/actions';

const DefaultForm = forwardRef(
  (
    {
      nextPage,
      deliveryLocation,
      isSwitch,
      switchHandler,
      setDeliveryLocation,
      setOrderData,
      orderData,
      rentalLocationId,
      returnLocation,
      setReturnLocation,
      startRentDate,
      setStartRentDate,
      setEndRentDate,
      selectedCar,
      setSelectedCar,
      numberInputOnWheelPreventChange,
      isOrderConfirmation,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const { showToast } = useAppContext();

    const { data: rentalServicesData } = useSelector((state) => state.services);

    // GLOBAL STATE
    const { dataZone, dataAirport } = useSelector((state) => state.shuttleAirport);
    const { data: vehicleAirportPackageData, selectedPackageVechile } = useSelector(
      (state) => state.vehicleAirportPackage,
    );

    // ENTER SELECTCAR IF SELECTCAR HAS BEEN ENTERED BEFORE
    useEffect(() => {
      if (!selectedCar) return;

      setOrderData({
        ...orderData,
        order_detail: { ...orderData.order_detail, vehicle_id: selectedCar?.vehicle_id },
      });
    }, [selectedCar]);

    // const intersectionHandler = () => {
    //   const currentPage = shuttleAirportData?.pagination?.page;

    //   if (currentPage >= shuttleAirportData?.pagination?.last_page) return;

    //   const baliLocationId = rentalLocationData?.find((item) => item.name === 'BALI')?.id;
    //   dispatch(getAllShuttleAirport({ locationId: baliLocationId, currentPage: currentPage + 1 }));
    // };

    const submitHandler = async () => {
      // validation
      const validDeliveryLocation = orderData.order_detail.rental_delivery_location !== '';
      const validReturnLocation = orderData.order_detail.rental_return_location !== '';
      const validDetailDeliveryLocation = orderData.order_detail.rental_delivery_location_detail.trim() !== '';
      const validDetailReturnLocation = orderData.order_detail.rental_return_location_detail.trim() !== '';
      const validStartBookingDate = orderData.order_detail.start_booking_date !== '';
      const validStartBookingTime = orderData.order_detail.start_booking_time !== '';
      const validFlightNumber = orderData.order_detail.flight_number.trim() !== '';
      const validVehicle = selectedCar && Object.keys(selectedCar).length !== 0;
      const validPackage = orderData.airport_transfer_package_id;
      const validBaggage = isNaN(orderData.order_detail.baggage) || orderData.order_detail.baggage === '';

      // check validation
      if (!validDeliveryLocation) {
        return showToast({ type: 'error', message: 'Pilih Lokasi Penjemputan' });
      } else if (!validReturnLocation) {
        return showToast({ type: 'error', message: 'Pilih Lokasi Pengantaran' });
      } else if (!validDetailDeliveryLocation) {
        return showToast({ type: 'error', message: 'Masukan Detail Lokasi Penjemputan' });
      } else if (!validDetailReturnLocation) {
        return showToast({ type: 'error', message: 'Masukan Detail Lokasi Pengantaran' });
      } else if (!validStartBookingDate) {
        return showToast({ type: 'error', message: 'Pilih Tanggal' });
      } else if (!validStartBookingTime) {
        return showToast({ type: 'error', message: 'Pilih Jam Jemput' });
      } else if (!validFlightNumber) {
        return showToast({ type: 'error', message: 'Masukan Nomor Penerbangan' });
      } else if (!validPackage) {
        return showToast({ type: 'error', message: 'Pilih Package Mobil' });
      } else if (!validVehicle) {
        return showToast({ type: 'error', message: 'Pilih Mobil' });
      } else if (validBaggage) {
        return showToast({ type: 'error', message: 'Masukan Banyak Koper yang dibawa' });
      }

      const subServiceAirportTransfer = rentalServicesData
        ?.find((item) => item.name === 'Sewa Mobil')
        ?.sub_services?.find((item) => item.name === 'Airport Transfer');

      // GET ORDER SUMMARY
      const payload = {
        order_type_id: orderData.order_type_id,
        start_booking_date: orderData.order_detail.start_booking_date,
        start_booking_time: orderData.order_detail.start_booking_time,
        airport_transfer_package_id: orderData.airport_transfer_package_id,
        location_id: rentalLocationId?.id,
        sub_services_id: subServiceAirportTransfer?.id,
      };

      try {
        const response = await dispatch(fetchSummaryAirportTransferOrder(payload));
        if (response.meta?.requestStatus === 'fulfilled') {
          nextPage();
          return;
        }
        showToast({ type: 'error', message: 'Terjadi Kesalahan' });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        showToast({ type: 'error', message: 'Terjadi Kesalahan' });
      }
    };

    useImperativeHandle(ref, () => ({
      actionInGrandGrandChild() {
        submitHandler();
      },
    }));

    return (
      <>
        <div className="airport-transfer-form__form__shuttle">
          {/* DELIVERY LOCATION */}
          <SelectFieldShuttle
            label="Lokasi Penjemputan"
            htmlFor="lokasi-penjemputan"
            value={deliveryLocation.id}
            data={isSwitch ? dataAirport?.shuttle : dataZone?.shuttle}
            onChange={(item) => {
              setDeliveryLocation(item);
              setOrderData({
                ...orderData,
                order_detail: { ...orderData.order_detail, rental_delivery_location: item.name },
              });
            }}
            placeholder="Masukan Lokasi Penjemputan"
            disable={!rentalLocationId.id ? true : isOrderConfirmation ? true : false}
            // intersectionAction={isSwitch ? null : intersectionHandler}
            searchFeature
          />

          {/* RETURN LOCATION */}
          <SelectFieldShuttle
            label="Lokasi Pengantaran"
            htmlFor="lokasi-pengantaran"
            value={returnLocation.id}
            data={isSwitch ? dataZone?.shuttle : dataAirport?.shuttle}
            onChange={(item) => {
              setReturnLocation(item);
              setOrderData({
                ...orderData,
                order_detail: { ...orderData.order_detail, rental_return_location: item.name },
              });
            }}
            placeholder="Masukan Lokasi Pengantaran"
            disable={!rentalLocationId.id ? true : isOrderConfirmation ? true : false}
            // intersectionAction={isSwitch ? intersectionHandler : null}
            searchFeature
          />

          {/* ICON SWITCH */}
          {!isOrderConfirmation && <SwitchIcon className="switch" onClick={switchHandler} />}
        </div>

        {/* DETAIL DELIVERY LOCATION */}
        <InputField
          label="Detail Lokasi"
          htmlFor="detail-lokasi-penjemputan"
          placeholder="Tulis detail lokasi"
          value={orderData.order_detail.rental_delivery_location_detail}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, rental_delivery_location_detail: e.target.value },
            })
          }
          disable={isOrderConfirmation}
        />

        {/* DETAIL RETURN LOCATION */}
        <InputField
          label="Detail Lokasi"
          htmlFor="detail-lokasi-pengantaran"
          placeholder="Tulis detail lokasi"
          value={orderData.order_detail.rental_return_location_detail}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, rental_return_location_detail: e.target.value },
            })
          }
          disable={isOrderConfirmation}
        />

        <div className="airport-transfer-form__form__baggage-passenger">
          <div className="start-date">
            {/* START DATE */}
            <SelectFieldDate
              label="Tanggal"
              htmlFor="tanggal-mulai"
              name="tanggal-mulai"
              handleDaySelect={(date) => {
                if (date) {
                  setStartRentDate(date);
                  setEndRentDate('');
                  setOrderData({
                    ...orderData,
                    order_detail: {
                      ...orderData.order_detail,
                      start_booking_date: format(date, 'yyyy-MM-dd').toString(),
                    },
                  });
                }
              }}
              selectedDay={startRentDate}
              value={startRentDate !== '' ? format(startRentDate, 'dd-MM-yyyy') : startRentDate}
              placeholder="Pilih Tanggal"
              disable={isOrderConfirmation}
            />
          </div>

          {/* START TIME */}
          <SelectFieldTime
            label="Jam Jemput"
            htmlFor="jam-mulai"
            placeholder="00:00"
            value={orderData.order_detail.start_booking_time}
            onChange={(hour, minute) => {
              setOrderData({
                ...orderData,
                order_detail: {
                  ...orderData.order_detail,
                  start_booking_time: `${hour}:${minute ? minute : '00'}`,
                },
              });
            }}
            disable={isOrderConfirmation ? true : startRentDate === '' ? true : false}
            showAllHours={true}
          />
        </div>

        {/* PACKAGE VEHICLE */}
        <SelectFieldDropdown
          label="Pilih Package Mobil"
          htmlFor="pilih-package-mobil"
          placeholder="Pilih Package Mobil"
          data={vehicleAirportPackageData?.packages}
          value={orderData?.airport_transfer_package_id}
          onChange={(e) => {
            setOrderData({
              ...orderData,
              airport_transfer_package_id: +e.target.id,
            });
            setSelectedCar(undefined);
          }}
          disable={
            isOrderConfirmation
              ? true
              : orderData.order_detail.start_booking_date === '' ||
                orderData.order_detail.start_booking_time === '' ||
                orderData.order_detail.rental_delivery_location === '' ||
                orderData.order_detail.rental_return_location === ''
          }
        />

        {/* FLIGTH NUMBER */}
        <InputField
          label="No. Penerbangan"
          htmlFor="no-penerbangan"
          placeholder="Tulis No. Penerbangan"
          value={orderData.order_detail.flight_number}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, flight_number: e.target.value },
            })
          }
          disable={isOrderConfirmation}
        />

        {/* VEHICLE */}
        <SelectFieldAirportPackage
          label="Pilih Mobil"
          placeholder="Cari Mobil ..."
          id="airport-package"
          data={selectedPackageVechile?.vehicles}
          selectedCar={selectedCar}
          onSelectCar={setSelectedCar}
          disable={
            isOrderConfirmation
              ? true
              : orderData.order_detail.start_booking_date === '' ||
                orderData.order_detail.start_booking_time === '' ||
                !orderData.airport_transfer_package_id
          }
        />

        <div className="airport-transfer-form__form__baggage-passenger">
          <InputField label="Kapasitas Koper" value={selectedCar?.max_suitecase || 0} disable />
          <InputField
            type="number"
            label="Koper yang dibawa"
            htmlFor="baggage"
            placeholder="Tulis Jumlah Koper"
            value={orderData.order_detail.baggage}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                order_detail: {
                  ...orderData.order_detail,
                  baggage: isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value),
                },
              })
            }
            disable={isOrderConfirmation}
            onWheel={numberInputOnWheelPreventChange}
          />
        </div>
      </>
    );
  },
);

DefaultForm.displayName = 'DefaultForm';
export default DefaultForm;

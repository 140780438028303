import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import DefaultForm from './DefaultForm';
import SingaPoreForm from './SingaporeForm';

const AirportTransferForm = forwardRef(
  (
    {
      // prevPage,
      nextPage,
      orderData,
      setOrderData,
      deliveryLocation,
      setDeliveryLocation,
      returnLocation,
      setReturnLocation,
      startRentDate,
      setStartRentDate,
      setEndRentDate,
      selectedCar,
      setSelectedCar,
      isOrderConfirmation,
      isSwitch,
      setIsSwitch,
      // shuttleData,
      rentalLocationId,
      // setRentalLocationId,
      luggageAirport,
      setLuggageAirport,
      passengersAirport,
      setPassengersAirport,
      startLocation,
      setStartLocation,
      endLocation,
      setEndLocation,
      locationData,
    },
    ref,
  ) => {
    const grandGrandChildRef = useRef();

    const switchHandler = () => {
      setIsSwitch((prevState) => !prevState);

      if (rentalLocationId?.is_special_airport_transfer) {
        setStartLocation(endLocation);
        setEndLocation(startLocation);
        setOrderData({
          ...orderData,
          order_detail: {
            ...orderData.order_detail,
            rental_delivery_location: orderData.order_detail.rental_return_location,
            rental_return_location: orderData.order_detail.rental_delivery_location,
            rental_delivery_location_detail: orderData.order_detail.rental_return_location_detail,
            rental_return_location_detail: orderData.order_detail.rental_return_location_detail,
          },
        });
        return;
      }

      setDeliveryLocation(returnLocation);
      setReturnLocation(deliveryLocation);
      setSelectedCar(undefined);
      setOrderData({
        ...orderData,
        order_detail: {
          ...orderData.order_detail,
          rental_delivery_location: orderData.order_detail.rental_return_location,
          rental_return_location: orderData.order_detail.rental_delivery_location,
          rental_delivery_location_detail: orderData.order_detail.rental_return_location_detail,
          rental_return_location_detail: orderData.order_detail.rental_delivery_location_detail,
        },
        airport_transfer_package_id: 0,
      });
    };

    const numberInputOnWheelPreventChange = (e) => {
      // Prevent the input value change
      e.target.blur();

      // Prevent the page/container scrolling
      e.stopPropagation();

      // Refocus immediately, on the next tick (after the current
      // function is done)
      setTimeout(() => {
        e.target.focus();
      }, 0);
    };

    useImperativeHandle(ref, () => ({
      actionInGrandChild() {
        if (grandGrandChildRef.current) {
          grandGrandChildRef.current.actionInGrandGrandChild();
        }
      },
    }));

    return (
      <div className="airport-transfer-form">
        <div className="airport-transfer-form__form">
          {!rentalLocationId?.is_special_airport_transfer ? (
            <DefaultForm
              ref={grandGrandChildRef}
              nextPage={nextPage}
              deliveryLocation={deliveryLocation}
              isSwitch={isSwitch}
              switchHandler={switchHandler}
              setDeliveryLocation={setDeliveryLocation}
              setOrderData={setOrderData}
              orderData={orderData}
              rentalLocationId={rentalLocationId}
              returnLocation={returnLocation}
              setReturnLocation={setReturnLocation}
              startRentDate={startRentDate}
              setStartRentDate={setStartRentDate}
              setEndRentDate={setEndRentDate}
              selectedCar={selectedCar}
              setSelectedCar={setSelectedCar}
              numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
              isOrderConfirmation={isOrderConfirmation}
            />
          ) : (
            <SingaPoreForm
              ref={grandGrandChildRef}
              nextPage={nextPage}
              selectedLuggage={luggageAirport}
              selectedPassengers={passengersAirport}
              setSelectedLuggage={setLuggageAirport}
              setSelectedPassengers={setPassengersAirport}
              startLocation={startLocation}
              setStartLocation={setStartLocation}
              rentalLocationId={rentalLocationId}
              endLocation={endLocation}
              setEndLocation={setEndLocation}
              locationData={locationData}
              isOrderConfirmation={isOrderConfirmation}
              isSwitch={isSwitch}
              switchHandler={switchHandler}
              orderData={orderData}
              setOrderData={setOrderData}
              numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
              startRentDate={startRentDate}
              setStartRentDate={setStartRentDate}
              setEndRentDate={setEndRentDate}
            />
          )}
        </div>
      </div>
    );
  },
);

AirportTransferForm.displayName = 'AirportTransferForm';
export default AirportTransferForm;

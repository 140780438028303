import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { InputField, SelectFieldPassengerAndLuggage, SelectFieldWithMap } from 'components/Global';
import InputTelpField from 'components/Global/InputTelpField';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import SelectFieldTime from 'components/Global/SelectFieldTime';
import { ReactComponent as FlightIcon } from 'icons/plane-icon.svg';
import { ReactComponent as DetailLocationIcon } from 'icons/detail-location-icon.svg';
import BlueMappin from 'icons/mappin-icon-blue.svg';
import YellowMappin from 'icons/mappin-icon-yellow.svg';
import { ReactComponent as SwitchIcon } from 'icons/switch-icon.svg';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { checkEmptyObject } from 'utils/functionality';
import axios from 'axios';
import { useAppContext } from 'components/Context/AppContext';
import { fetchSummaryAirportTransferOrder } from 'features/orders/actions';

const SingaPoreForm = forwardRef(
  (
    {
      nextPage,
      selectedLuggage,
      selectedPassengers,
      setSelectedLuggage,
      setSelectedPassengers,
      startLocation,
      setStartLocation,
      rentalLocationId,
      endLocation,
      setEndLocation,
      locationData,
      isOrderConfirmation,
      isSwitch,
      switchHandler,
      orderData,
      setOrderData,
      numberInputOnWheelPreventChange,
      startRentDate,
      setStartRentDate,
      setEndRentDate,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const { showToast } = useAppContext();

    // GLOBAL STATE
    const { dataZone, dataAirport } = useSelector((state) => state.shuttleAirport);
    const { data: summaryData } = useSelector((state) => state.summaryOrder);

    const [newDataZone, setNewDatazone] = useState([]);
    const [newDataAirport, setNewDataAirport] = useState([]);

    const passangerAndLuggageHandler = (passengers, luggage) => {
      setSelectedPassengers(passengers);
      setSelectedLuggage(luggage);
      setOrderData({
        ...orderData,
        order_detail: {
          ...orderData.order_detail,
          adult_passenger: passengers.adults,
          child_passenger: passengers.child,
          regular_suitcase: luggage.luggage,
          large_suitcase: luggage.suitcaseXl,
        },
      });
    };

    const searchFunc = async (input, boundingBox) => {
      let results;
      try {
        results = await axios.get(
          `https://nominatim.openstreetmap.org/search?q=${input}&addressdetails=1&viewbox=${boundingBox}&bounded=1&format=json`,
        );
        return results.data?.length > 0
          ? {
              latlng: [results.data[0].lat, results.data[0].lon],
            }
          : undefined;
      } catch (error) {
        showToast({ type: 'error', message: error });
      }
    };

    const getLocationCoord = async (input) => {
      let boundingBox;
      try {
        const results = await axios.get(
          `https://nominatim.openstreetmap.org/search?q=${rentalLocationId?.name}&format=json`,
        );
        if (results.data?.length > 0) {
          boundingBox = results.data[0].boundingbox;
        }
      } catch (error) {
        showToast({ type: 'error', message: error });
      }
      try {
        const results = await searchFunc(
          input,
          `${boundingBox[2]},${boundingBox[0]},${boundingBox[3]},${boundingBox[1]}`,
        );
        return { results, bounds: boundingBox };
      } catch (error) {
        showToast({ type: 'error', message: error });
      }
    };

    const submitHandler = async () => {
      // some validation
      const validUsername = orderData?.user_name.replace(/\s/g, '').length !== 0;
      const validUserWaNumber = orderData?.wa_number.replace(/s/g, '').length !== 0;
      const validStartBookingDate = orderData.order_detail.start_booking_date !== '';
      const validStartBookingTime = orderData.order_detail.start_booking_time !== '';
      const validPassengersAndLuggage =
        (orderData.order_detail.adult_passenger || orderData.order_detail.child_passenger) &&
        (orderData.order_detail.large_suitcase || orderData.order_detail.regular_suitcase);
      const validFlightNumber = orderData.order_detail.flight_number.trim() !== '';
      const validOriginLocation = orderData.order_detail.origin && !checkEmptyObject(orderData.order_detail.origin);
      const validDestinationLocation =
        orderData.order_detail.destination && !checkEmptyObject(orderData.order_detail.destination);
      const validDetailOriginLocation = orderData.order_detail.rental_delivery_location_detail !== '';
      const validDetailDestinationLocation = orderData.order_detail.rental_return_location_detail !== '';
      const validMeetAndGreetBoard =
        orderData.order_detail.customer_name_meet_and_greet &&
        orderData.order_detail.customer_name_meet_and_greet !== '';

      if (!validUsername) {
        return showToast({ type: 'error', message: 'Masukan Customer Name' });
      } else if (!validUserWaNumber) {
        return showToast({ type: 'error', message: 'Masukan Customer Contact' });
      } else if (!validStartBookingDate) {
        return showToast({ type: 'error', message: 'Pilih Pickup Date' });
      } else if (!validStartBookingTime) {
        return showToast({ type: 'error', message: 'Pilih Pickup Time' });
      } else if (!validPassengersAndLuggage) {
        return showToast({ type: 'error', message: 'Piih Passengers Dan Luggage' });
      } else if (!validFlightNumber) {
        return showToast({ type: 'error', message: 'Masukan Flight Number' });
      } else if (!validOriginLocation) {
        return showToast({ type: 'error', message: 'Pilih Lokasi Pickup' });
      } else if (!validDestinationLocation) {
        return showToast({ type: 'error', message: 'Pilih Lokasi Drop Off' });
      } else if (!validDetailOriginLocation) {
        return showToast({ type: 'error', message: 'Masukan Detail Lokasi Pickup' });
      } else if (!validDetailDestinationLocation) {
        return showToast({ type: 'error', message: 'Masukan Detail Lokasi Drop Off' });
      } else if (!validMeetAndGreetBoard && isSwitch) {
        return showToast({ type: 'error', message: 'Masukan Meet And Greet Board' });
      }

      const payload = {
        start_booking_date: orderData.order_detail.start_booking_date,
        start_booking_time: orderData.order_detail.start_booking_time,
        order_type_id: orderData.order_type_id,
        rental_delivery_id: startLocation.id,
        rental_return_id: endLocation.id,
        adult_passenger: orderData.order_detail.adult_passenger,
        child_passenger: orderData.order_detail.child_passenger,
      };

      try {
        const response = await dispatch(fetchSummaryAirportTransferOrder(payload));
        if (response.meta?.requestStatus === 'fulfilled') {
          nextPage();
          return;
        }
        showToast({ type: 'error', message: 'Terjadi Kesalahan' });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        showToast({ type: 'error', message: 'Terjadi Kesalahan' });
      }
    };

    useImperativeHandle(ref, () => ({
      actionInGrandGrandChild() {
        submitHandler();
      },
    }));

    useEffect(() => {
      setOrderData({ ...orderData, phone_country_code: '+65' });
    }, []);

    useEffect(() => {
      if (
        !dataAirport ||
        checkEmptyObject(dataAirport) ||
        !dataZone ||
        checkEmptyObject(dataZone) ||
        !dataAirport.shuttle ||
        !dataZone.shuttle ||
        dataAirport.shuttle.length === 0 ||
        dataZone.shuttle.length === 0 ||
        !rentalLocationId ||
        !rentalLocationId.is_special_airport_transfer
      )
        return;

      const mappingNewLocation = async (data, type) => {
        if (data.shuttle[0].location_id !== rentalLocationId.id) return;

        let newLocation = [];

        for (let child of data.shuttle) {
          try {
            const coord = await getLocationCoord(child.name);
            newLocation.push({
              ...child,
              y: coord.results.latlng[0],
              x: coord.results.latlng[1],
              bounds: [
                [coord.bounds[0], coord.bounds[1]],
                [coord.bounds[2], coord.bounds[3]],
              ],
              label: child.name,
            });
          } catch (error) {
            showToast({ type: 'error', message: error });
          }
        }

        if (type === 'zone') {
          setNewDatazone(newLocation);
        } else if (type === 'airport') {
          setNewDataAirport(newLocation);
        }
      };

      mappingNewLocation(dataZone, 'zone');
      mappingNewLocation(dataAirport, 'airport');
    }, [dataAirport, dataZone, rentalLocationId]);

    useEffect(() => {
      if (
        !orderData.order_detail.start_booking_date ||
        !orderData.order_detail.start_booking_time ||
        !orderData.order_type_id ||
        !startLocation?.name ||
        !endLocation?.name ||
        !orderData.order_detail.adult_passenger ||
        !orderData.order_detail.child_passenger ||
        isOrderConfirmation
      )
        return;

      const payload = {
        start_booking_date: orderData.order_detail.start_booking_date,
        start_booking_time: orderData.order_detail.start_booking_time,
        order_type_id: orderData.order_type_id,
        rental_delivery_id: startLocation.id,
        rental_return_id: endLocation.id,
        adult_passenger: orderData.order_detail.adult_passenger,
        child_passenger: orderData.order_detail.child_passenger,
      };

      dispatch(fetchSummaryAirportTransferOrder(payload));
    }, [
      orderData.order_detail.start_booking_date,
      orderData.order_detail.start_booking_time,
      orderData.order_type_id,
      orderData.order_detail.adult_passenger,
      orderData.order_detail.child_passenger,
      startLocation,
      endLocation,
    ]);

    return (
      <>
        <InputField
          label="Nama Customer"
          htmlFor="customer-name"
          placeholder="Tulis Nama Customer"
          value={orderData.user_name}
          onChange={(e) => setOrderData({ ...orderData, user_name: e.target.value })}
          disable={isOrderConfirmation}
        />
        <InputTelpField
          label="Contact Number"
          htmlFor="contact-number"
          value={orderData.wa_number}
          onChange={(e) => setOrderData({ ...orderData, wa_number: e.target.value, phone_country_code: '+65' })}
          disable={isOrderConfirmation}
          countryCodeValue={orderData?.phone_country_code}
          onWheel={numberInputOnWheelPreventChange}
          onSelectCountry={(code) => setOrderData({ ...orderData, phone_country_code: code })}
        />

        <div className="airport-transfer-form__form__pick-up-singapore">
          <SelectFieldDate
            label="Pickup Date"
            htmlFor="pickup-date"
            name="tanggal-mulai"
            handleDaySelect={(date) => {
              if (date) {
                setStartRentDate(date);
                setEndRentDate('');
                setOrderData({
                  ...orderData,
                  order_detail: {
                    ...orderData.order_detail,
                    start_booking_date: format(date, 'yyyy-MM-dd').toString(),
                  },
                });
              }
            }}
            selectedDay={startRentDate}
            value={startRentDate !== '' ? format(startRentDate, 'dd-MM-yyyy') : startRentDate}
            placeholder="Pilih Tanggal"
            disable={isOrderConfirmation}
          />
          <SelectFieldTime
            label="Pick Up Time"
            htmlFor="pickup-time"
            placeholder="00:00"
            value={orderData.order_detail.start_booking_time}
            onChange={(hour, minute) => {
              setOrderData({
                ...orderData,
                order_detail: {
                  ...orderData.order_detail,
                  start_booking_time: `${hour}:${minute ? minute : '00'}`,
                },
              });
            }}
            disable={isOrderConfirmation ? true : startRentDate === '' ? true : false}
            showAllHours={true}
          />
        </div>

        <div className="airport-transfer-form__form__baggage-and-flight-number">
          <SelectFieldPassengerAndLuggage
            label="Passengers and Luggage"
            htmlFor="passengers-and-luggage"
            selectedLuggage={selectedLuggage}
            selectedPassengers={selectedPassengers}
            onSelect={passangerAndLuggageHandler}
            disable={isOrderConfirmation}
            maxPassengers={6}
          />

          <InputField
            label="Flight Numbers"
            htmlFor="flight-numbers"
            placeholder="Tulis Flight Numbers"
            value={orderData.order_detail.flight_number}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                order_detail: { ...orderData.order_detail, flight_number: e.target.value },
              })
            }
            disable={isOrderConfirmation}
            icon={<FlightIcon />}
            iconPosition="start"
          />
        </div>

        <div className="airport-transfer-form__form__location-map">
          <SelectFieldWithMap
            label="Pick Up"
            htmlFor="pickup-location"
            placeholder={isSwitch ? 'Pilih Airport' : 'Pilih Lokasi'}
            iconUrl={BlueMappin}
            value={startLocation.name}
            setValue={setStartLocation}
            centerCoord={locationData.latlng}
            positionCoord={startLocation.coord || [0, 0]}
            onClick={(item) => {
              setStartLocation({ id: item.id, name: item.label, lat: item.y, lng: item.x, coord: [item.y, item.x] });
              setOrderData({
                ...orderData,
                order_detail: {
                  ...orderData.order_detail,
                  rental_delivery_location: item.label,
                  origin: { lat: +item.y, long: +item.x },
                },
              });
            }}
            showMap={rentalLocationId?.name}
            mapBounds={locationData.bounds}
            disable={isOrderConfirmation ? true : !rentalLocationId.id}
            airportTransferSwitch
            dataAirportTransferSpecial={isSwitch ? newDataAirport : newDataZone}
          />
          <SelectFieldWithMap
            label="Drop Off"
            htmlFor="drop-off-location"
            placeholder={isSwitch ? 'Pilih Lokasi' : 'Pilih Airport'}
            iconUrl={YellowMappin}
            value={endLocation.name}
            setValue={setEndLocation}
            centerCoord={locationData?.latlng}
            positionCoord={endLocation.coord || [0, 0]}
            onClick={(item) => {
              setEndLocation({ id: item.id, name: item.label, lat: item.y, lng: item.x, coord: [item.y, item.x] });
              setOrderData({
                ...orderData,
                order_detail: {
                  ...orderData.order_detail,
                  rental_return_location: item.label,
                  destination: { lat: +item.y, long: +item.x },
                },
              });
            }}
            showMap={rentalLocationId?.name}
            mapBounds={locationData.bounds}
            disable={isOrderConfirmation ? true : !rentalLocationId}
            airportTransferSwitch
            dataAirportTransferSpecial={isSwitch ? newDataZone : newDataAirport}
          />

          {/* ICON SWITCH */}
          {!isOrderConfirmation && <SwitchIcon className="switch" onClick={switchHandler} />}
        </div>

        <InputField
          label="Detail Lokasi"
          htmlFor="pickup-detail"
          placeholder="Tulis detail lokasi"
          value={orderData?.order_detail?.rental_delivery_location_detail}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, rental_delivery_location_detail: e.target.value },
            })
          }
          icon={<DetailLocationIcon />}
          iconPosition="start"
          disable={isOrderConfirmation}
        />

        <InputField
          label="Detail Lokasi"
          htmlFor="dropoff-detail"
          placeholder="Tulis detail lokasi"
          value={orderData?.order_detail?.rental_return_location_detail}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, rental_return_location_detail: e.target.value },
            })
          }
          icon={<DetailLocationIcon />}
          iconPosition="start"
          disable={isOrderConfirmation}
        />

        {isSwitch && (
          <InputField
            label="Meet and Greet Board"
            htmlFor="meet-and-greet-board"
            placeholder="Example : Alex From SG, etc."
            value={orderData?.order_detail?.customer_name_meet_and_greet || ''}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                order_detail: { ...orderData.order_detail, customer_name_meet_and_greet: e.target.value },
              })
            }
          />
        )}

        <div className="airport-transfer-form__form__total-price">
          <h3>Total Price : </h3>
          <p>SGD : {summaryData?.total_payment || 0}</p>
        </div>
      </>
    );
  },
);

SingaPoreForm.displayName = 'SingaPoreForm';
export default SingaPoreForm;

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { getAllCategories } from 'features/category/actions';
import { getServices } from 'features/services/actions';
import { getRentalLocationById, getRentalLocationByServices } from 'features/rental-location/actions';
import { getAllAirportLocation } from 'features/airport-location/actions';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import TableLocations from './TableLocations';
import { Button, TableWrapper } from 'components/Global';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as AirportTransferCarFilledIcon } from 'icons/airport-transfer-car-filled-icon.svg';
import { createAirportPackages, deleteAirportPackages, getAllAirportPackages } from 'features/airport-packages/actions';

const DetailHarga = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showToast, setShowConfirmation, setShowSpinner } = useAppContext();

  const { data: categoryData } = useSelector((state) => state.category);
  const { data: servicesData } = useSelector((state) => state.services);
  // eslint-disable-next-line no-unused-vars
  const { data: rentalLocationData, selectedLocation } = useSelector((state) => state.rentalLocation);
  const { data: airportLocationData } = useSelector((state) => state.airportLocation);
  const { data: airportPackagesData, isLoading } = useSelector((state) => state.airportPackages);

  const [searchParams] = useSearchParams();
  const [category, setCategory] = useState('');
  const [location, setLocation] = useState('');
  const [pickUpLocation, setPickUpLocation] = useState({});
  const [dropOffLocation, setDropOffLocation] = useState({});
  const [pickUpLocationType, setPickUpLocationType] = useState({});
  const [dropOffLocationType, setDropOffLocationType] = useState({});
  const [price, setPrice] = useState('');
  const [route, setRoute] = useState([]);

  const mode = searchParams.get('mode');
  const categoryId = searchParams.get('category_id');
  const locationId = searchParams.get('location_id');

  const chooseCategoryHandler = (e) => {
    setCategory(e.target.id);
  };

  const chooseLocationHandler = (e) => {
    setLocation(e.target.id);
    dispatch(
      getAllAirportLocation({
        page: 0,
        limit: 100,
        filters: [{ filter_key: 'location_id', filter_value: +e.target.id }],
      }),
    );
    setPickUpLocation({});
    setPickUpLocationType({});
    setDropOffLocation({});
    setDropOffLocationType({});
    setRoute([]);
  };

  const choosePickupLocationHandler = (location) => {
    setPickUpLocation(location);
    setPickUpLocationType(location.airport_location_type);
  };

  const chooseDropOffLocationHandler = (location) => {
    setDropOffLocation(location);
    setDropOffLocationType(location.airport_location_type);
  };

  const addRouteHandler = () => {
    const validPickupLocation = Object.keys(pickUpLocation).length !== 0;
    const validDropoffLocation = Object.keys(dropOffLocation).length !== 0;
    const validPickupLocationType = Object.keys(pickUpLocationType).length !== 0;
    const validDropoffLocationType = Object.keys(dropOffLocationType).length !== 0;
    const validPrice = +price !== 0 || price !== '';

    if (!validPickupLocation) {
      return showToast({ type: 'error', message: 'Pilih Daerah Lokasi Penjemputan' });
    }

    if (!validPickupLocationType) {
      return showToast({ type: 'error', message: 'Pilih Tipe Daerah Lokasi Penjemputan' });
    }

    if (!validDropoffLocation) {
      return showToast({ type: 'error', message: 'Pilih Daerah Lokasi Pengantaran' });
    }

    if (!validDropoffLocationType) {
      return showToast({ type: 'error', message: 'Pilih Tipe Daerah Lokasi Pengantaran' });
    }

    if (!validPrice) {
      return showToast({ type: 'error', message: 'Masukan Harga' });
    }

    const newRoute = {
      id: Math.random() + 1 + '', // temp id
      fee: +price,
      dropoff_location_id: dropOffLocation?.id,
      pickup_location_id: pickUpLocation?.id,
      dropoff_location_name: dropOffLocation?.name,
      pickup_location_name: pickUpLocation?.name,
      dropoff_location_type_id: dropOffLocationType?.id,
      pickup_location_id_type_id: pickUpLocationType?.id,
      dropoff_location_type_name: dropOffLocationType?.name,
      pickup_location_id_type_name: pickUpLocationType?.name,
    };

    setRoute((prevState) => [...prevState, newRoute]);
    setPickUpLocation({});
    setPickUpLocationType({});
    setDropOffLocation({});
    setDropOffLocationType({});
    setPrice('');
  };

  const deleteRouteHandler = (id) => {
    if (mode === 'add-item') {
      const filteredRoute = [...route].filter((item) => item.id !== id);
      setRoute(filteredRoute);
    } else if (mode === 'edit') {
      setShowConfirmation({
        message: 'Apakah anda yakin ingin menghapus?',
        show: true,
        valueButton: 'Hapus',
        variant: 'danger',
        onClick: async () => {
          try {
            await dispatch(deleteAirportPackages(id)).unwrap();
            showToast({ type: 'success', message: 'Data Berhasil di hapus' });
            dispatch(
              getAllAirportPackages({
                page: 0,
                limit: 0,
                categoryId: +categoryId,
                locationId: +locationId,
              }),
            );
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            showToast({ type: 'error', message: `${error.message}` });
          }
        },
      });
    }
  };

  const submitHandler = () => {
    const validCategory = category !== '';
    const validLocation = location !== '';
    const validAirportPackageLine = route.length !== 0;

    if (!validLocation) {
      return showToast({ type: 'error', message: 'Pilih Lokasi' });
    }

    if (!validCategory) {
      return showToast({ type: 'error', message: 'Pilih Kategori Mobil' });
    }

    if (!validAirportPackageLine) {
      return showToast({ type: 'error', message: 'Masukan Rute' });
    }

    const payload = {
      category_id: +category,
      location_id: +location,
      airport_package_line: route.map((item) => ({
        fee: item.fee,
        dropoff_location_id: item.dropoff_location_id,
        pickup_location_id: item.pickup_location_id,
      })),
    };

    let messageToast;

    if (mode === 'add-item') {
      messageToast = 'Berhasil Tambah Harga Airport Transfer';
    } else {
      messageToast = 'Berhasil Edit Harga dan Lokasi';
    }

    setShowConfirmation({
      message: 'Apakah anda yakin ingin menyimpan Airport Transfer Price?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(createAirportPackages(payload)).unwrap();
          showToast({ type: 'success', message: messageToast });
          navigate('/airport-transfer-car?tab-active=Setting Harga');
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          showToast({ type: 'error', message: `${error.message}` });
        }
      },
    });
  };

  useEffect(() => {
    dispatch(getAllCategories({ page: 1 }));
    dispatch(getServices());
  }, []);

  useEffect(() => {
    if (!location) return;
    dispatch(getRentalLocationById(location));
  }, [location]);

  useEffect(() => {
    if (!servicesData || !servicesData.length) return;

    const airportServicesId = servicesData[0].sub_services.find((item) => item.name === 'Airport Transfer')?.id;
    dispatch(getRentalLocationByServices({ service_id: servicesData[0].id, sub_service_id: airportServicesId }));
  }, [servicesData]);

  useEffect(() => {
    if (!location) return;

    dispatch(getRentalLocationById(location));
  }, [location]);

  // useEffect(() => {
  //   if (checkEmptyObject(selectedLocation)) return;
  //   if (selectedLocation?.is_special_airport_transfer) {
  //     setCategory('');
  //   }
  // }, [selectedLocation]);

  useEffect(() => {
    if (!categoryId || !locationId || mode === 'add-item') return;

    setCategory(+categoryId);
    setLocation(+locationId);
    dispatch(
      getAllAirportPackages({
        page: 0,
        limit: 0,
        categoryId: +categoryId,
        locationId: +locationId,
      }),
    );
    dispatch(
      getAllAirportLocation({
        page: 0,
        limit: 100,
        filters: [{ filter_key: 'location_id', filter_value: +locationId }],
      }),
    );
  }, [categoryId, locationId]);

  useEffect(() => {
    if (!Object.keys(airportPackagesData).length || !airportPackagesData.data || mode === 'add-item') return;

    let mappingRoute = [];

    mappingRoute = airportPackagesData.data.map((item) => ({
      id: item.id,
      fee: item.fee,
      dropoff_location_id: item.dropoff_location_id,
      pickup_location_id: item.pickup_location_id,
      dropoff_location_name: item.dropoff_location?.name,
      pickup_location_name: item.pickup_location?.name,
      dropoff_location_type_id: item.dropoff_location?.airport_location_type_id,
      pickup_location_id_type_id: item.pickup_location?.airport_location_type_id,
      dropoff_location_type_name: item.dropoff_location?.airport_location_type?.name,
      pickup_location_id_type_name: item.pickup_location?.airport_location_type.name,
    }));

    setRoute(mappingRoute);
  }, [airportPackagesData]);

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else if (!isLoading) {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <div className="detail-setting-harga">
      <header className="detail-setting-harga__header" onClick={() => navigate(-1)}>
        <LeftArrow />
        <p>Kembali</p>
      </header>

      <TableWrapper
        icon={<AirportTransferCarFilledIcon fill="#009EF7" width="25px" height="25px" />}
        title="Airport Transfer Car"
      >
        <div className="detail-setting-harga__body">
          <SelectFieldDropdown
            label="Lokasi"
            htmlFor="lokasi"
            data={rentalLocationData || []}
            value={location}
            onChange={chooseLocationHandler}
            placeholder="Pilih Lokasi"
            disable={mode !== 'add-item' ? true : false}
          />

          <SelectFieldDropdown
            label="Nama Kategori Mobil"
            htmlFor="nama-kategori-mobil"
            data={categoryData.category || []}
            value={category}
            onChange={chooseCategoryHandler}
            placeholder="Pilih Kategori Mobil"
            disable={mode !== 'add-item' ? true : false}
          />

          <TableLocations
            airportLocationData={airportLocationData}
            pickUpLocation={pickUpLocation}
            setPickUpLocation={choosePickupLocationHandler}
            dropOffLocation={dropOffLocation}
            setDropOffLocation={chooseDropOffLocationHandler}
            pickUpLocationType={pickUpLocationType}
            dropOffLocationType={dropOffLocationType}
            price={price}
            setPrice={setPrice}
            route={route}
            setRoute={setRoute}
            onAddRoute={addRouteHandler}
            onDelete={deleteRouteHandler}
            mode={mode}
            currency={selectedLocation?.currency || ''}
          />

          <div className="action-button">
            {(mode === 'add-item' || mode === 'edit') && (
              <Button className="action-button__save" onClick={submitHandler}>
                Simpan
              </Button>
            )}
          </div>
        </div>
      </TableWrapper>
    </div>
  );
};

export default DetailHarga;

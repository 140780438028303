import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext } from 'components/Context/AppContext';
import Tabs, { TabPane } from 'components/Global/Tabs';
import TipeDaerah from './TipeDaerah';
import Daerah from './Daerah';
import Harga from './Harga';
import ButtonAdd from 'components/Global/ButtonAdd';
import { resetSelectedLocation } from 'features/rental-location/slice';
import { useDispatch } from 'react-redux';

const AirportTransferCarComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setShowAddAirportLocationType, setShowAddAirportLocation } = useAppContext();

  const [activeTab, setActiveTab] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const tabActive = searchParams.get('tab-active');

  const handleAdd = () => {
    switch (tabActive) {
      case 'Tipe Daerah':
        return setShowAddAirportLocationType(true);
      case 'Master Daerah':
        return setShowAddAirportLocation(true);
      case 'Setting Harga':
        dispatch(resetSelectedLocation());
        return navigate('/airport-transfer-car/price?mode=add-item');
      default:
    }
  };

  useEffect(() => {
    if (!tabActive) {
      setSearchParams({ 'tab-active': 'Tipe Daerah' });
    } else {
      setActiveTab(tabActive);

      if (tabActive !== 'Master Daerah') {
        localStorage.removeItem('airport-location');
      }
    }
  }, [tabActive]);

  return (
    <div className="airport-transfer-car">
      {/* TABLE */}
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
        <TabPane label="Tipe Daerah">
          <TipeDaerah />
        </TabPane>
        <TabPane label="Master Daerah">
          <Daerah />
        </TabPane>
        <TabPane label="Setting Harga">
          <Harga />
        </TabPane>
      </Tabs>

      {/* BUTTON */}
      <ButtonAdd onClick={handleAdd} label={`Tambah ${activeTab}`} />
    </div>
  );
};

export default AirportTransferCarComponent;
